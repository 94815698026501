<div class="modal-container">
    <div class="close-btn-container">
        <mat-icon mat-dialog-close class="cursor-pointer">close</mat-icon>
    </div>
    <section class="side-filter-container">
        <div class="div-label">
            <label>{{'filtersSideMenu.textFilterBy' | translate}}:</label>
            <button class="modalFilterBtnClear" *ngIf="isSelected" (click)="clearFilters()" >{{'filtersSideMenu.textClearFilters' | translate}}</button>
        </div>
    
        <div class="mt-4 mb-4">
            <ln-filter-chips
                    [filterSelected]="getFiltersSelected()"
                    (removed)="filterWasRemoved($event)">
            </ln-filter-chips>
        </div>
    
        <div class="mb-4 mt-4">
            <ln-filter-additional-services
                    [filtersServices]="getFiltersServicesList()"
                    (selected)="filterWasSelected($event)"
                    (removed)="filtersServicesWasRemoved($event)"
                    [filterToDeselect]="filterToDeselect"
                    [filterToSelect]="filterToSelect"
                   >
            </ln-filter-additional-services>
            
        </div>
    
        <div class="mt-6">
            <ln-filter-list-expansion-panel
                    *ngFor="let filter of getFiltersList()"
                    [filterItem]="filter"
                    
                    (clicked)="filterWasSelected($event)">
            </ln-filter-list-expansion-panel>
        </div>
        <hr>
    </section>
    <div class="modalFilterButtonsContainer">
        <button class="modalFilterBtnApply" (click)="applyAllFilters()" mat-dialog-close >{{'filtersSideMenu.textApplyFilters' | translate}}</button>
        
    </div>
</div>
