<div class="custom-pagination ml-auto">
    <button (click)="previousPage()" [disabled]="page === 0">
        <</button>
    <ng-container *ngFor="let p of pages">
        <button 
            (click)="setCurrentPage(p - 1)" 
            [class.active]="p - 1 === page"
            [class.ellipsis]="p === -1"
            [disabled]="p === -1">
            {{ p === -1 ? '...' : p }}
        </button>
    </ng-container>
    <button (click)="nextPage()" [disabled]="page + 1 === totalPages">></button>
</div>
