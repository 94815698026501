    <button (click)="toggleMenu($event)" mat-button [matMenuTriggerFor]="menu">
        <div class="flex flex-cols-2 items-center orderBy">
            <span class="filter_general">{{ 'sortPosts.textOrderBy' | translate }} &nbsp;</span>
            <span class="font-semibold">{{ ('sortPosts.' + textSortSelected) | translate }}</span>
            <mat-icon>keyboard_arrow_down</mat-icon>
        </div>
    
    </button>
    <mat-menu #menu="matMenu" xPosition="before">
        <div *ngFor="let option of options; last as lastIndex">
        <button (click)="selectOption(option[0], option[1], option[2])" mat-menu-item>
            <span>{{ 'sortPosts.textOrderBy' | translate }} &nbsp;</span>
            <span class="font-semibold">{{ ('sortPosts.' + option[2]) | translate }}</span>
        </button>
        <mat-divider *ngIf="!lastIndex"/>
        </div>
    </mat-menu>
