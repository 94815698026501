import {Component, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {FiltersInterface} from "../../interfaces/filters.interface";
import { StoreFiltersService } from '../../services/storeFilters.service';


@Component({
    selector: 'app-sort-posts',
    templateUrl: './sort-posts.component.html',
    styleUrls: ['./sort-posts.component.css']
})
export class SortPostsComponent implements OnInit, OnDestroy {

    @Output() sortFilters = new EventEmitter<FiltersInterface>();

    @ViewChild(MatMenuTrigger)
    menuTrigger!: MatMenuTrigger;

    sortFiltersSelected: FiltersInterface = {};
    options = [
        ['price', 'asc', 'lowerPrice'],
        ['price', 'desc', 'higherPrice'],
        ['published_date', 'desc', 'newest'],
        ['published_date', 'asc', 'older'],
    ]
    textSortSelected!: string;
    subscription!: Subscription;
    menuOpen = false;

    constructor(private _activatedRoute: ActivatedRoute, private storeFilterService: StoreFiltersService) {
    }

    ngOnInit() {
        this.subscription = this._activatedRoute.queryParams.subscribe(params => {
            if (params['sort'] === undefined) {
                this.textSortSelected = 'newest';
                this.sortFiltersSelected.sort = 'published_date';
                this.sortFiltersSelected.order = 'desc';
                this.storeFilterService.setOrderFilter(this.sortFiltersSelected);
            } else {
                this.sortFiltersSelected.sort = params['sort'];
                this.sortFiltersSelected.order = params['order'];
                const option = this.options.find(param =>
                    param[0] === params['sort'] && param[1] === params['order']
                );
                this.textSortSelected = option ? option[2] : 'newest';
            }
        })
    }

    selectOption(sort: string, order: string, text: string) {
        this.textSortSelected = text;

        let sorting = {sort: sort}
        let orderFilter = {order: order}
        this.storeFilterService.setOrderFilter(sorting);
        this.storeFilterService.setOrderFilter(orderFilter);
        this.sortFiltersSelected.order = order == 'desc' ? 'desc' : 'asc';
        this.sortFiltersSelected.sort = sort;
        this.sortFilters.emit(this.sortFiltersSelected);
        this.menuOpen = false;

    }

    @HostListener('document:click', ['$event'])

    onClick(event: MouseEvent) {
      const menu = document.querySelector('.cdk-overlay-pane');
      if (menu && !menu.contains(event.target as Node)) {
        this.menuOpen = false; // Cerrar el menú si se hace clic fuera de él
      }
    }

    // Decorador que escucha eventos del HOST, en este caso el scroll
    @HostListener('window:scroll', ['$event'])
    onWindowScroll(event: Event) {
        if (this.menuOpen) {
            const menu = document.querySelector('.cdk-overlay-pane');
            if (menu && !menu.contains(event.target as Node)) {
                this.closeMenu();
            }
        }
    }

     // Método que se ejecuta cuando se le da click al boton del menu de filtros
     toggleMenu(event: MouseEvent) {
        event.stopPropagation();
        this.menuOpen = !this.menuOpen; // lo transforma a true
        //console.log("MENU ABIERTO" + this.menuOpen);
      }

     openMenu() {
        this.menuOpen = true;
      }

     // Método para cerrar el menú desplegable a partir del trigger de angular material
     closeMenu() {
        this.menuOpen = false;
        //console.log("MENU CERRADO" + this.menuOpen);
        if (this.menuTrigger) {
            this.menuTrigger.closeMenu();
        }
      }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe()
    }
}
