<div class="header">
    <button mat-icon-button class="close-button" (click)="close()">
        <mat-icon class="close-icon">close</mat-icon>
    </button>
    <h2 class="text-center titleClose" matDialogTitle>{{title | translate }}</h2>
</div>
<mat-dialog-content class="mat-typography">
    <div class="text-center text-xl mt-4 ln-green subtitleDialog" *ngIf="subTitle">{{subTitle | translate}}</div>
    <p class="text-center text-lg ln-green" *ngIf="message">{{message | translate}}</p>
</mat-dialog-content>
<mat-dialog-actions align="center"  class="mbs-16 actions">
    <div class="mx-w sm:mb-0 mr-0 ">
        <button *ngIf="!account"mat-raised-button (click)="confirm()" color="primary" [mat-dialog-close]="true">{{buttonOk | translate}}</button>
        <button *ngIf="account" mat-raised-button (click)="login()" color="primary" [mat-dialog-close]="true">{{buttonOk | translate}}</button>
    </div>
    <div class="mx-w sm:mb-0 mr-0 ">
        <button *ngIf="!account" mat-stroked-button color="primary"
                class="registBtn"
                (click)="close()"
                [mat-dialog-close]="true">
            {{buttonCancel | translate}}
        </button>
        <button *ngIf="account" mat-stroked-button color="primary"
                class="registBtn"
                (click)="register()"
                [mat-dialog-close]="true">
            {{buttonCancel | translate}}
        </button>
        
    </div>
</mat-dialog-actions>