
export class Rubros {

    readonly rubros = [
        // {
        //     "id": 'REAL_STATE',
        //     "name": "Inmobiliaria"
        // },
        // {
        //     "id": 'OWNER',
        //     "name": "Propietario"
        // },
        // {
        //     "id": 'REALTOR',
        //     "name": "Corredor"
        // },
        {
            "id": 'USER',
            "name": "servicesForm.textProfileType",
            "en": "INTERESTED / USER",
            "es": "Interesado / Usuario"
        }
    ]
}
