import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslationService } from '../../services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreFiltersService } from '../../services/storeFilters.service';
import { PropertyDetailPostModel } from '../../../core/domain/property-detail-post-model';
import { calcularPrecioTotalConTarifas, calcularTotalConArrayDescuento, calcularTotalNoches } from '../../helpers/helpers';

@Component({
  selector: 'app-reservartion-card',
  templateUrl: './reservartion-card.component.html',
  styleUrls: [
    './reservartion-card.component.css',
    './2reservation-card.component.css',
  ],
})
export class ReservartionCardComponent implements OnInit, OnChanges {
  isLoaded = true;
  accomodationType: string = '';
  bedType: string = '';
  bathroomType: string = '';
  currentLang: string | undefined;
  precioOferta: number = 0;
  precioNocheCalculado: number = 0;
  cantidadHuespedes: number = 0;
  noches: number = 0;
  isFavorite: boolean = false;
  showDescription: boolean = false;
  //TODO REVISAR EN EL NGONINIT SI ES FAVORITO DEL USUARIO

  //Asignarlo a la card por el hecho de haber filtrado Y clickeado para llevarlo al detalle?
  Adults: number = 0;
  Pets: number = 0;
  Infants: number = 0;
  Children: number = 0;
  rangeValues = '';
  @Input()
  bedTypes!: any[];
  @Input()
  card!: PropertyDetailPostModel;
  @Input()
  definePrice!: (price: string) => void;

  cantidadNoches: number = 1;
  precioTotalNoches: number = 0;
  precioMostrar: string = '0';
  tarifaLimpieza: number = 0;
  totalMostrar: string = '0';
  precioPorNoche: number = 0;
  tarifaLunaNueva: number = 1;
  total: number = 0;
  porcentajeLunaNueva = 0.03;
  showAlert = false;
  esp: boolean = true;
  currentLanguage = 'es';
  hoverText: string | undefined;
  fromDate: any;
  toDate: any;
  precioNocheOferta: number = 0;
  tieneOferta: boolean=false;
  totalConTodo:number=0;

  constructor(
    private _translate: TranslationService,
    private translateService: TranslateService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _activatedRoute: ActivatedRoute,
    private _cdr: ChangeDetectorRef,
    private storeFilters: StoreFiltersService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this._changeDetectorRef.detectChanges();
  }

  ngOnInit() {
    this._translate.onLangChange.subscribe(() => {
      this.setAccomodationType();
    });
    this.setAccomodationType();
    this._changeDetectorRef.detectChanges();

    setTimeout(() => {
      this.fromDate = this.storeFilters.getFilter().fromDate;
      this.toDate = this.storeFilters.getFilter().toDate;
    }, 200);

    setTimeout(() => {
      this.getCantidadNoches();
      this.calcularPrecioTotal();
    }, 400);
  }

  setAccomodationType() {
    const descriptionToTranslationKey: { [key: string]: string } = {
      Apartments: 'card.labelApartment',
      Houses: 'card.labelHouse',
      BedBreakfast: 'card.labelBedAndBreakFast',
      Suite: 'card.labelSuite',
    };

    const bedTypesToTranslationKey: { [key: string]: string } = {
      queenBed: 'filtersSideMenu.textQueenBed',
      kingBed: 'filtersSideMenu.textKingBed',
      singleBed: 'filtersSideMenu.textIndividualBed',
      matrimonialBed: 'filtersSideMenu.textMatrimonialBed',
    };

    if (
      this.card.propiedades.tipoPropiedad.descripcion.includes('BedBreakfast')
    ) {
      this.showDescription = true;
      var bathTranslationKey = '';
      var bedTranslationKey = '';

      this.card.ambientes.forEach((element: any) => {
        if (element.codTipoAmbiente === 'AMB1') {
          bathTranslationKey = 'filtersSideMenu.textPrivateBathroom';
        }
        if (element.codTipoAmbiente === 'AMB7') {
          bathTranslationKey = 'filtersSideMenu.textSharedBathroom';
        }
        if (element.codTipoAmbiente == 'AMB5') {
          element.objetos.forEach((element2: any) => {
            if (
              element2.catalogoObjetos.descripcion in bedTypesToTranslationKey
            ) {
              bedTranslationKey =
                bedTypesToTranslationKey[element2.catalogoObjetos.descripcion];
              if (bedTranslationKey) {
                this._translate
                  .getTranslation(bedTranslationKey)
                  .subscribe((res: string) => {
                    this.bedType = res;
                  });
              }
            }
          });
        }
      });
      if (bathTranslationKey) {
        this._translate
          .getTranslation(bathTranslationKey)
          .subscribe((res: string) => {
            this.bathroomType = res;
          });
      }
    }

    const translationKey =
      descriptionToTranslationKey[
        this.card.propiedades.tipoPropiedad.descripcion
      ];
    if (translationKey) {
      this._translate
        .getTranslation(translationKey)
        .subscribe((res: string) => {
          this.accomodationType = res;
        });
    }
  }

  calcularPrecioTotal() {
    this.precioPorNoche = this.card.tarifas[0].precioNoche;
    let oferta = 0;

    const start=new Date(this.getDateFormat(this.fromDate));
    const end= new Date(this.getDateFormat(this.toDate));
    const result= calcularTotalConArrayDescuento(this.card.ofertas, this.precioPorNoche, start.toISOString(),end.toISOString());
    this.totalConTodo=0;
    this.tieneOferta=false;
    if(result.total>0){
      this.tieneOferta=true;
      //Hay descuento, por lo que retorna el total de noches que tienen descuento con su valor final
      if(result.nochesTotales>=this.cantidadNoches){
        //Todas las noches seleccionadas tienen descuento, esto da el total
        this.totalConTodo=result.total;
      }

      if(result.nochesTotales<this.cantidadNoches){
        //Quedan noches para calcular el total. Se debe sumar la diferencia de noches por precio
        const partialValueNoDiscount=calcularTotalNoches(this.precioPorNoche, null, (this.cantidadNoches-result.nochesTotales));
        this.totalConTodo = partialValueNoDiscount+result.total;
      }
    }

    if(result.total==0){
      //No hay descuento. Null a oferta
      this.precioNocheOferta = calcularTotalNoches(this.precioPorNoche, null, this.cantidadNoches);
      this.precioTotalNoches = this.precioNocheOferta;
    }


    const calculated = calcularTotalNoches(
      this.card.tarifas[0].precioNoche,
      null,
      this.cantidadNoches
    );
    this.precioTotalNoches = calculated;
    this.tarifaLimpieza = this.card.tarifaLimpieza;
    this.definePrice(this.precioTotalNoches.toString());
    this.getTotal(calculated);
    this._cdr.detectChanges();
  }

  getTotal(totalNights: number) {
    //Se incluye la tarifa de limpieza?
    this.tarifaLunaNueva = (totalNights + 0) * this.porcentajeLunaNueva;
    this.tarifaLunaNueva = parseFloat(this.tarifaLunaNueva.toFixed(1));
    if(this.totalConTodo>0){
      this.totalConTodo= this.totalConTodo+this.tarifaLunaNueva+Number(this.tarifaLimpieza)
      }
    this.total = calcularPrecioTotalConTarifas(
      this.precioTotalNoches,
      this.tarifaLunaNueva,
      Number(this.tarifaLimpieza)
    );
    this.definePrice(this.total.toString());
  }

  getDateFormat(date:string){
    const [year, month, day] = date.split('-').map(Number);
    return new Date(year, month - 1, day);
  }

  getCantidadNoches() {
    this.fromDate = this.storeFilters.getFilter().fromDate;
    this.toDate = this.storeFilters.getFilter().toDate;
    // Convertir las cadenas de texto a objetos Date
    let checkIn = new Date(this.fromDate);
    let checkOut = new Date(this.toDate);
    // Calcular la diferencia de tiempo en milisegundos
    const diffTime = Math.abs(checkOut.getTime() - checkIn.getTime());

    // Convertir la diferencia de tiempo en días y redondear hacia arriba
    if (isNaN(Math.ceil(diffTime / (1000 * 60 * 60 * 24)))) {
      console.error('error de fechas');
      this.cantidadNoches = 1;
    } else {
      this.cantidadNoches = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }
  }

  getValoresCard() {
    return {
      cantidadNoches: this.cantidadNoches,
      tarifaLunaNueva: this.tarifaLunaNueva,
      precioTotalNoches: this.precioTotalNoches.toFixed(2),
      tarifaLimpieza: Number(this.tarifaLimpieza).toFixed(2),
      precioPorNoche: this.precioPorNoche.toFixed(2),
      accomodationType: this.accomodationType,
      totalConDescuento:this.totalConTodo.toFixed(2),
    };
  }
}
