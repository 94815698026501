import { Mapper } from "../../core/base/mapper";
import { AlojamientosResponseServer } from "../entity/mapa/alojamientos-filters-entity";
import { AlojamientosResponseModel } from "../../core/domain/mapa/alojamiento-filters-model";
import { environment } from "../../../environments/environment";
import { ImagenesModel } from "../../core/domain/mapa/imagenes-model";
import { tipoPropiedad } from "../../shared/components/cards/cards.component";
import { CatalogoObjetos } from '../entity/mapa/alojamientos-filters-response';
import { ObjetosEntity } from "../entity/mapa/objetos-entity";
import { Descuentos } from "../../shared/interfaces/descuentos-interface";



export class AlojamientosFiltersMapper implements Mapper<AlojamientosResponseServer, AlojamientosResponseModel> {

  constructor() { }

  mapFrom(entity: AlojamientosResponseServer): AlojamientosResponseModel {
    const alojamiento = entity.alojamientos.map(alojamiento => {
      const permiteMascotas = Boolean(Number(alojamiento.propiedad[0].permiteMascotas)) === true
      const pics: ImagenesModel[] = [];
      if (alojamiento.imagenes.length > 0 && alojamiento.imagenes) {
        alojamiento.imagenes.forEach(elm => {
          const urlParts = elm.url.split('/');
          const fileName = urlParts.pop();
          const fileBase = urlParts.join('/');
          pics.push(<ImagenesModel><unknown>{
            idImagen: elm.idImagen,
            posicion: elm.posicion,
            url: {
              sm: environment.firebaseConfig.storageBucket + "/" + fileBase + "/sm/" + fileName,
              md: environment.firebaseConfig.storageBucket + "/" + fileBase + "/md/" + fileName,
              lg: environment.firebaseConfig.storageBucket + "/" + fileBase + "/lg/" + fileName
            }
          })
        });
      }
      // const descuentos:Descuentos[]=[];
      // alojamiento.ofertas.map(v=>{console.log()})
      const objetos = Array.isArray(alojamiento.catalogoObjeto) ? alojamiento.catalogoObjeto.map(objeto => ({
        descripcion: objeto.descripcion,
        idObjeto: objeto.idObjeto,
      })) : [];
      return {
        idAlojamiento: alojamiento.idAlojamiento,
        idPropiedad: alojamiento.idPropiedad,
        nombre: alojamiento.nombre,
        maximoHuespedes: alojamiento.maximoHuespedes,
        propiedad: alojamiento.propiedad ? alojamiento.propiedad.map(propiedad => ({
          nombre: propiedad.nombre,
          direccion: propiedad.direccion,
          ciudad: propiedad.ciudad,
          tipoPropiedad: propiedad.tipoPropiedad,
          latitud: propiedad.latitud,
          longitud: propiedad.longitud,
          permiteMascotas: permiteMascotas,
          distanciaAlCentro: propiedad.distanciaAlCentro,
        })) : [],
        PrecioCalculado: alojamiento.PrecioCalculado||0,
        totalSinOferta: alojamiento.totalSinOferta||0,
        tarifas: alojamiento.tarifas ? alojamiento.tarifas.map(tarifa => ({
          precioNoche: tarifa.precioNoche,
        })) : [],

        imagenes: pics,
        ofertas: alojamiento.ofertas,
        objetos: objetos,
        ambientes: Array.isArray(alojamiento.ambientes)
          ? alojamiento.ambientes.map(ambiente => ({
            idAmbiente: ambiente.idAmbiente,
            codTipoAmbiente: ambiente.codTipoAmbiente,
            tiposAmbiente: ambiente.tiposAmbiente,
          }))
          : [],
        favorito: parseInt(alojamiento.favorito),
        cantidadBanos: alojamiento.cantidadBanos,
        cantidadCamas: alojamiento.cantidadCamas,
      };
    }
    );
    return {
      totalAlojamientos: entity.totalAlojamientos,
      alojamientos: alojamiento
    };
  }




  mapFromList(entities: AlojamientosResponseServer[]): AlojamientosResponseModel[] {
    return entities.map(entity => this.mapFrom(entity));
  }

  mapTo(param: AlojamientosResponseModel): AlojamientosResponseServer {
    throw new Error("Method not implemented.");
  }
  mapToList(param: AlojamientosResponseModel[]): AlojamientosResponseServer[] {
    throw new Error("Method not implemented.");
  }

}
