import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {FilterContainerType, FilterValues} from "../../meta/filter-side-menu-meta";

@Component({
  selector: 'ln-filter-list-expansion-panel',
  templateUrl: './ln-filter-list-expansion-panel.component.html',
  styleUrls: ['./ln-filter-list-expansion-panel.component.scss']
})
export class LnFilterListExpansionPanelComponent {

  @Input() filterItem!: FilterContainerType;
  @Input() disabled: boolean | undefined;
  @Output() clicked = new EventEmitter<FilterValues>()

  wasClicked(item: FilterValues) {
    this.clicked.emit(item)
  }

}
