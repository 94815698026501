<div class="container">
    <img class="image" src="{{image}}">
    <p class="title">Bed and Breadfast {{bbName}}</p>
    <div class="publications">
        <img src="../../../../assets/icons/home.svg" class="icon">
        <p *ngIf="publications==1" class="subtitle">{{publications}} {{'bbProfile.publication' | translate}}</p>
        <p *ngIf="publications>1" class="subtitle">{{publications}} {{'bbProfile.publications' | translate}}</p>
    </div>
    <div (click)="contactForm()" class="mx-w btn-continue">
        <ln-button-loading
                [text]="'bbProfile.contact' | translate">
        </ln-button-loading>
</div>

</div>