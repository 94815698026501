import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FilterValues } from "../../meta/filter-side-menu-meta";

@Component({
    selector: 'ln-filter-additional-services',
    templateUrl: './ln-filter-additional-services.component.html',
    styleUrls: ['./ln-filter-additional-services.component.css']
})
export class LnFilterAdditionalServicesComponent implements OnChanges {

    @Input() filtersServices!: FilterValues[];
    @Input() filterToDeselect!: any;
    @Input() filterToSelect!: any;

    @Output() selected = new EventEmitter<FilterValues>();
    @Output() removed = new EventEmitter<FilterValues>();

    // Variable adicional para controlar el estado seleccionado
    selectedStates: { [key: string]: boolean } = {};

    constructor(private cdr: ChangeDetectorRef) { }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['filterToDeselect'] && this.filterToDeselect !== undefined) {
            this._onRemoved(this.filterToDeselect);
        }
        if (changes['filterToSelect'] && this.filterToSelect !== undefined) {
            this.filterToSelect.forEach((item: FilterValues) => {
                this._onSelected(item);
            });


        }
    }

    onChanged({ checked }: any, item: FilterValues) {
        checked ? this._onSelected(item) : this._onRemoved(item);
    }

    private _onSelected(item: FilterValues) {
        item.isSelected = true;
        this.selected.emit(item);
        this.selectedStates[item['filterType']] = true; // Actualiza la variable adicional
        this.cdr.detectChanges();
    }

    private _onRemoved(item: FilterValues) {
        item.isSelected = false;
        this.removed.emit(item);
        this.selectedStates[item['filterType']] = false; // Actualiza la variable adicional
        this.cdr.detectChanges();
    }

    // Método para limpiar los filtros
    clearFilters() {
        this.filtersServices.forEach((item: FilterValues) => {
            item.isSelected = false;
            this.selectedStates[item['filterType']] = false; // Actualiza la variable adicional
        });
        this.cdr.detectChanges();
    }
}
