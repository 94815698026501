export class ServicesTypes {

    readonly services = [
        {
            "id": 'management_property',
            "name": "servicesForm.labelManagement",
            "en": "Property Management",
            "es": "Administración y gestión de propiedades"
        },
        {
            "id": 'interior_design',
            "name": "servicesForm.labelDesign",
            "en": "Interior Design",
            "es": "Diseño de interiores"
        },
        {
            "id": 'another_request',
            "name": "servicesForm.labelAnotherRequest",
            "en": "Another request",
            "es": "Otra solicitud"
        },
    ]
}
