<div class="hintContainer">
    <article class="card">

        <div class="info-container">
            <div class="info">
                <div class="icon">
                    <img src="../../../../assets/icons/errorModal.svg" alt="">
                </div>
                <div class="text">
                    <h2>{{ "reservationError.title" |translate }}</h2>
                    <p>{{ "reservationError.text" |translate }}</p>
                </div>
            </div>
            
        </div>
    </article>
    <span class="button">
        <button mat-button mat-dialog-close class="buttonClose">
            {{ "reservationError.retry" |translate }}
        </button>
    </span>

</div> 