import { Component, Input, OnInit } from '@angular/core';
import { GalleryItem, ImageItem } from "ng-gallery";
import { PostPictureModel } from "../../../core/domain/post-picture-model";
import { ImagenesModel } from '../../../core/domain';

@Component({
  selector: 'app-images-gallery',
  templateUrl: './images-gallery.component.html',
  styleUrls: ['./images-gallery.component.css']
})
export class ImagesGalleryComponent implements OnInit {
  @Input() thumbPosition: 'top' | 'left' | 'right' | 'bottom' = 'left';
  @Input() postPictureModels: ImagenesModel[] = [];
  @Input() showThumb: boolean = true;
  @Input() counter: boolean = true;
  @Input() isBBProfile: boolean = false
  galleryItems: GalleryItem[] = [];

  ngOnInit() {
    this.processImages();
  }

  processImages() {
    this.postPictureModels.forEach(postPicture => {
      let image 
      if(postPicture.url.md||postPicture.url.lg||postPicture.url.sm){
        image=postPicture.url.md.toString();
      }
      else{
        image=postPicture.url.toString();
      }
      this.galleryItems.push(
        new ImageItem({
          src: image,
          thumb: image
        })
      )
    }
    );
  }
}
