<div class="bookingCardContainer">

    <app-ln-date-picker [cardBooking]="'datePicker'" (dateRangeSelected)="onDateRangeChanged()" [fechasBloqueo]="fechasBloqueo"></app-ln-date-picker>
    <app-ln-guest-selector [customClass]="'cardCustom'"></app-ln-guest-selector>
    <div class="checks">
        <div>
            <h3>Check In</h3>
            <p>{{checkin}} PM</p>
        </div>
        <div>
            <h3>Check Out</h3>
            <p>{{checkout}} AM</p>
        </div>
    </div>


</div>