import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {CityPageActions, CityApiActions, CitySelectors} from '../index';
import {EMPTY, exhaustMap, map, withLatestFrom} from "rxjs";
import {CityUseCaseService} from "../../core/usecase/city-use-case.service";
import {Store} from "@ngrx/store";


@Injectable()
export class CityEffects {

    constructor(
        private actions$: Actions,
        private _cityUseCaseService: CityUseCaseService,
        private _store: Store
    ) {
    }

    // loadAll$ = createEffect(() => this.actions$.pipe(
    //     ofType(CityPageActions.init),
    //     withLatestFrom(this._store.select(CitySelectors.cities)),
    //     exhaustMap((initAction) => {
    //         if (!initAction[1].length) {
    //             return this._cityUseCaseService
    //                 .get()
    //                 .pipe(
    //                     map((cities) => CityApiActions.loadAllCities({cities}))
    //                 )
    //         }
    //         return EMPTY
    //     })
    // ))
}
