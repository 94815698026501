import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface ToastMessage {
  text: string;
  type: 'success' | 'error' | 'info';
  position?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private toastSubject = new BehaviorSubject<ToastMessage | null>(null);
  toast$ = this.toastSubject.asObservable();

  showToast(message: string, type: 'success' | 'error' | 'info', position: string = 'toast-top-right') {
    this.toastSubject.next({ text: message, type: type, position: position });

    setTimeout(() => {
      this.clearToast(); 
    }, 3000); 
  }

  clearToast() {
    this.toastSubject.next(null);
  }
}

