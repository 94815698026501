<div class="card-container">
    <ln-card-skeleton *ngIf="!isLoaded"></ln-card-skeleton>

    <article class="card">
       
            <img *ngIf="card.imagenes.length >0 " src={{card.imagenes[0].url.sm}} alt="" />
            <img *ngIf="card.imagenes.length === 0" class="imagenCard" alt="" />
       
        <div class="info-container">
            <div class="card-header">
                <div>
                    <div class="accomodationTypeContainer">
                        <h1 *ngIf="!showDescription" class="accomodationType">{{accomodationType}}</h1>
                        <h1 *ngIf="showDescription" class="accomodationType">{{accomodationType}} {{card.nombre}}</h1>   
                    </div>
                    <div  class="accomodationTypeContainer">
                        <p *ngIf="showDescription" class="bbType">{{card.propiedades.tipoPropiedad.descripcion}}
                        {{card.propiedades.nombre}}</p>  
                    </div>
                    <div *ngIf="card.propiedades.tipoPropiedad.descripcion !== 'BedBreakfast'"
                            class="detailsRoom-container">
                            <div *ngIf="card.cantidadCamas>=0" class="flex items-center">
                                <img src="../../../../assets/icons/bedroom.svg" alt="" />
                                <p *ngIf="card.cantidadCamas<=1">{{card.cantidadCamas}}
                                    {{'card.labelBedroom'|translate}}</p>
                                <p *ngIf="card.cantidadCamas>1">{{card.cantidadCamas}}
                                    {{'card.labelBedrooms'|translate}}</p>
                            </div>
                            <div *ngIf="card.cantidadBanos>=0" class="flex items-center">
                                <img src="../../../../assets/icons/shower.svg" alt="" />
                                <p *ngIf="card.cantidadBanos<=1">{{card.cantidadBanos}}
                                    {{'card.labelBathroom'|translate}}</p>
                                <p *ngIf="card.cantidadBanos>1">{{card.cantidadBanos}}
                                    {{'card.labelBathrooms'|translate}}</p>
                            </div>
                        </div>
                        <!-- Tipos a mostrar para bed and breakfast -->
                        <div *ngIf="card.propiedades.tipoPropiedad.descripcion==='BedBreakfast'"
                            class="detailsRoom-container">
                            <div class="camas-container">
                                <ng-container *ngFor="let cama of bedTypes">
                                    <div *ngIf="cama.cantidad > 0" class="cama-item">
                                        <img class="icons" src="../../../../assets/icons/hotel.svg">
                                        <p class="aligned-with-icon">
                                            {{ cama.cantidad }} {{ cama.tipo| translate }}
                                        </p>
                                    </div>
                            
                                </ng-container>
                                <div class="flex items-center" *ngIf="card.cantidadBanos>=1">
                                    <img src="../../../../assets/icons/shower.svg" alt="" />
                                    <p>{{card.cantidadBanos}} {{bathroomType|translate}}</p>
                                </div>
                                <div class="flex items-center" *ngIf="card.cantidadBanos===0">
                                    <img src="../../../../assets/icons/shower.svg" alt="" />
                                    <p>{{card.cantidadBanos}} {{'card.labelBathroom'|translate}}</p>
                                </div>
                            </div>
                            <!-- <div class="flex items-center" *ngIf="card.cantidadCamas>=1">
                                <img src="../../../../assets/icons/bedroom.svg" alt="" />
                                <p>{{card.cantidadCamas}} {{bedType|translate}}</p>
                            </div>
                            <div class="flex items-center" *ngIf="card.cantidadCamas===0">
                                <img src="../../../../assets/icons/bedroom.svg" alt="" />
                                <p>{{card.cantidadCamas}} {{'card.labelBed'|translate}}</p>
                            </div> -->
                        
                        </div>
                </div>
            </div>

            <div class="card-footer">
                <div class="location-container">
                    <img src="../../../../assets/icons/location-solid.svg" alt="" />
                    <h2 class="location">{{card.propiedades.direccion}}   </h2>
                </div>
                <p>  {{'card.textDistance1'|translate}} {{card.propiedades.distanciaAlCentro}} km
                    {{'card.textDistance2'|translate}}</p>
            </div>
            <div class="card-body">
                    <a>{{precioPorNoche | currency: 'USD':'symbol':'1.2-2'}} USD X {{cantidadNoches}} {{'propertyReservation.textNight' | translate}}  </a>
                    <p>$</p>
                    <p class="price">{{precioTotalNoches |  number : '1.2-2'}}</p>
                    <a>{{'propertyReservation.textCleaningFee' | translate}} </a>
                    <p>$</p>
                    <p class="price">{{tarifaLimpieza |  number : '1.2-2'}}</p>
                    <a>{{'propertyReservation.textLunaNuevaService' | translate}}  </a>
                    <p>$</p>
                    <p class="price">{{tarifaLunaNueva |  number : '1.2-2'}}</p>
            </div>
            <hr class="mt-2">
            <div class="priceLine">
                <a>{{'card.textTotalImpuestos' |translate}} </a>
                <p>$</p>
                <p class="price" [ngClass]="{'totalLineThrough': tieneOferta}">{{total |  number : '1.2-2'}}</p>
            </div>

            <div class="priceLine totalConDescuento" *ngIf="tieneOferta">
                <a>{{'card.textTotalImpuestosOferta' |translate}} </a>
                <p class="totalConDescuento">$</p>
                <p class="price totalConDescuento">{{totalConTodo |  number : '1.2-2'}}</p>
            </div>
        </div>
    </article>
</div>