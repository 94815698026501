<div *ngIf="apiLoaded$ | async" class="g-map">

    <div class="mb-4 sm:ml-0 ml-4" *ngIf="!isPropertyDetails">
        <label *ngIf="posts?.length as postsLength; else notPosts">{{ 'googleMap.textExist' | translate }} {{postsLength}} {{ 'googleMap.textPropertyInArea' | translate }}</label>
        <ng-template #notPosts>
            <label> {{ 'googleMap.textNoProperties' | translate }} </label>
        </ng-template>
    </div>

     <google-map
            width="100%"
            [height]="height"
            [center]="center"
            [zoom]="zoom"
            [options]="options"
            (idle)="refresh()"
            (mapClick)="closeInfoWindows()"
    >   

            <div *ngIf="isPropertyDetails; then marketDefault; else markerPer"></div>
            <ng-template #markerPer >
                <map-marker #marker="mapMarker"
                            *ngFor="let post of posts; index as i; trackBy: trackByFn"
                            [icon]="getIcon(post.PrecioCalculado, post.favoritos, post.propiedad[0].tipoPropiedad) "
                            [options]="markerOptions"
                            [position]="{lat: parse(post.propiedad[0].latitud), lng: parse(post.propiedad[0].longitud)}"
                            (mapClick)="openInfoWindow(marker, post)"
                            (mapMouseover)="setZIndex(marker, 100)"
                            (mapMouseout)="setZIndex(marker, 10)"
                >
                    <map-info-window
                            [options]="infoWindowsOptions">
                        <ng-container *ngIf="postSelected">
                          <ln-card-maps
                                   
                                [data]="postSelected"
                                (refreshEvent)="refresh()"
                            ></ln-card-maps> 
                        </ng-container>
                    </map-info-window>
                </map-marker>
            </ng-template>

        <ng-template #marketDefault>
            <map-marker
                    [options]="markerOptions"
                    [position]="center"
            ></map-marker>
        </ng-template>
    </google-map> 

</div>
