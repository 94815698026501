<nav [ngClass]="authLayout ? 'headerPadding' : 'header'" class="bg-white border-gray-200 rounded ">
  <div class="flex flex-wrap items-center justify-between mx-auto">
    <div class="flex">
      <a (click)="goToHomeClearFilters($event)" class="flex items-center cursor-pointer">
        <img src="../../../../assets/icons/logo.svg" class="h-16" alt="Logo" />
      </a>
    </div>
    <ng-container *ngIf="show">
       <!-- Menú Escritorio -->
      <div class="flex flex-wrap items-center">
        <div class="flex items-center md:order-2 ml-3.5">
          <ln-translate [showText]="false" class="mr-3 md:hidden"/>
          <button type="button" class="inline-flex items-center p-2 text-sm rounded-lg md:hidden w-auto"
              (click)="showMenuMobile()">
            <mat-icon aria-hidden="false" aria-label="Example home icon">{{ icon }}</mat-icon>
          </button>
        </div>
        <div class=" items-center justify-between hidden w-full md:flex md:w-auto md:order-1">
          <ul class="flex flex-col mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row 
                     md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white">
            <li>
              <ln-translate/>
            </li>
            <a
              routerLink="/services"
               class="text-color block py-2 pl-3 pr-4 a-secondary-color rounded md:bg-transparent md:p-0"
               aria-current="page">{{'header.linkServices' | translate}}
            </a>
            <a
            routerLink="/us"
             class="text-color block py-2 pl-3 pr-4 a-secondary-color rounded md:bg-transparent md:p-0"
             aria-current="page">{{'headerMenu.textUs' | translate}}
          </a>
            <li *ngIf="isLogedin">
              <div class="flex" 
                   (mouseenter)="onHover()" 
                   (mouseleave)="onHoverOut()"
                   >
                <a (click)="toggleSubmenu()"
                  class="text-color block py-2 pl-3 pr-8 a-secondary-color rounded md:bg-transparent md:p-0"
                  aria-current="page"
                  >{{'header.linkMyAccount' | translate}}</a>
                <span *ngIf="!hasDocumentImage" class="alert-icon relative">
                    <img src="../../../../../assets/icons/field-alert.svg">
                </span>           
              <ul *ngIf="showSubmenu" 
                         id="submenu" 
                         class="fixed right-8 mt-2 rounded-md shadow-lg bg-white p-4 z-10"
                         [ngClass]="esp ? 'spanish' : 'english'">
                  <!-- Submenú -->
                  <li class="flex items-center my-2 pl-4 text-left group">
                    <div class="flex items-center">
                      <a [routerLink]="['my-account/my-data']" class="text-color block py-2 pl-2 pr-2 px-4 a-secondary-color rounded md:bg-transparent md:p-0" aria-current="page" >
                        {{ 'header.linkMyData' | translate }}
                      </a>
                      <span *ngIf="!hasDocumentImage" class=" ml-1 relative" (mouseenter)="showAlert = true" (mouseleave)="showAlert = false">
                        <img src="../../../../../assets/icons/field-alert.svg" >
                        <div [ngClass]="esp ? 'spanish-tooltip' : 'english-tooltip'">
                          <div *ngIf="showAlert"
                               class=" ml-4 tooltip border border-gray-200 rounded-md shadow-md z-100">
                            <!-- Mensaje de alerta -->
                            <p class="text-tooltip text-sm text-white">{{ 'header.textTooltip' | translate }}</p>
                          </div>
                        </div>
                      </span>
                    </div>
                  </li>
                  <li *ngIf="!isLogedin" 
                      class="my-2 pl-4 text-left">
                    <a [routerLink]="['sessions/register']"
                       class="text-color block py-2 pl-2 pr-2 px-4 a-secondary-color rounded md:bg-transparent md:p-0"
                       aria-current="page">{{'header.linkCreateAccount' | translate}}
                    </a>
                  </li>
                  <li class="my-2 pl-4 text-left" (click)="goToMyReservations()">
                    <a class="text-color block py-2 pl-2 pr-2 px-4 a-secondary-color rounded md:bg-transparent md:p-0"
                     aria-current="page">{{'header.linkMyReserves' | translate}}
                    </a>
                  </li>

                  <li class="my-2 pl-4 text-left" (click)="goToFavorites()">
                    <a class="text-color block py-2 pl-2 pr-4 px-4 a-secondary-color rounded md:bg-transparent md:p-0"
                     aria-current="page">{{'header.linkMyFavorites' | translate}}
                    </a>
                  </li>
              </ul>
              </div>
              
          </li>
          <li *ngIf="!isLogedin" class="relative">
            
            <div  (mouseenter)="onHover()" (mouseleave)="onHoverOut()">
                <a (click)="toggleSubmenu()" 
                  class="text-color block py-2 pl-3 pr-8 a-secondary-color rounded md:bg-transparent md:p-0"
                  aria-current="page"
                  >{{'header.linkCreateAccount' | translate}}</a>
              <!-- Submenú -->
                <ul
                 *ngIf="showSubmenu " 
                  id="submenu" 
                  [ngClass]="esp ? 'spanish-create' : 'english-create'"
                  class="fixed right-8 mt-2 w-40 rounded-md shadow-lg bg-white p-2 z-10">
                <li *ngIf="!isLogedin" 
                    class="my-2 pl-4 text-left">
                  <a routerLink="sessions/register"
                    class="text-color block py-2 pl-2 pr-2 px-4 a-secondary-color rounded md:bg-transparent md:p-0"
                    aria-current="page">{{'header.linkCreateAccount' | translate}}
                  </a>
                </li>
                <li class="my-2 pl-4 text-left">
                  <a (click)="goToMyReservations()" class="text-color block py-2 pl-2 pr-2 px-4 a-secondary-color rounded md:bg-transparent md:p-0"
                  aria-current="page">{{'header.linkMyReserves' | translate}}
                  </a>
                </li>
                <li class="my-2 pl-4 text-left">
                  <a (click)="goToFavorites()" class="text-color block py-2 pl-2 pr-4 px-4 a-secondary-color rounded md:bg-transparent md:p-0"
                  aria-current="page">{{'header.linkMyFavorites' | translate}}
                  </a>
                </li>
              </ul>
            </div>
            
        </li>
            <li *ngIf="!isLogedin">
              <a [routerLink]="['sessions/signin']"
                  class="text-color a-secondary-color block py-2 pl-3 pr-4 roundedmd:bg-transparent md:p-0">
                  {{'header.linkLogin' | translate}}</a>
            </li>
            <li *ngIf="isLogedin">
              <a (click)="openDialogCloseSession()"
                  class="text-color a-secondary-color block py-2 pl-3 cursor-pointer pr-4 rounded md:bg-transparent md:p-0">
                  {{'header.linkLogout' | translate}}</a>
            </li>
          </ul>
        </div>
      </div>
      <!-- Menú Mobile -->
      <div *ngIf="showMenu" class="md:hidden fixed top-20 rounded right-4 z-50 box">
        <div class="items-center justify-between w-full rounded-sm" id="navbar-cta">
          <ul *ngIf="!isLogedin " class="flex flex-col  rounded shadow-lg bg-white">
            
            <li *ngIf="!isLogedin">
              <a routerLink="sessions/register" class="option hover:text-gray-900 block pb-1 pt-4 px-6">{{'headerMenu.textSignIn' | translate}}</a>
            </li>
            <li>
              <a (click)="goToMyReservations()" class="option hover:text-gray-900 block py-1 px-6">{{'headerMenu.textMyReservations' | translate}}</a>
            </li>
            <li>
              <a (click)="openDialogCreateAccount()" class="option hover:text-gray-900 block py-1 px-6">{{'headerMenu.textMyFavourites' | translate}}</a>
            </li>
            <li>
              <a routerLink="services" class="text-gray-700 hover:text-gray-900 block py-1 px-6 option">{{'headerMenu.textServices' | translate}}</a>
            </li>
            <li>
              <a routerLink="us" class="text-gray-700 hover:text-gray-900 block py-1 px-6 option">{{'headerMenu.textUs' | translate}}</a>
            </li>
            
            <li *ngIf="!isLogedin">
              <a routerLink="sessions/signin" class=" hover:text-gray-900 block pt-1 pb-4 px-6 optionAction">{{'headerMenu.textLogIn' | translate}}</a>
            </li>
            
          </ul>
          <ul *ngIf="isLogedin" class="flex flex-col space-y-1 rounded shadow-lg bg-white">
            <li *ngIf="isLogedin">
              <span class="flex items-center">
                <a routerLink="my-account/my-data" class="option hover:text-gray-900 block pt-4 pb-1 px-6">{{'headerMenu.textMyAccount' | translate}}</a>
                <span class="ml-[-20px] relative hoverButtonText" [attr.hoverText]="hoverText" (click)="onButtonClick()">
                  <img src="../../../../../assets/icons/field-alert.svg ">
                </span>
              </span>
            <li>
              <a (click)="goToMyReservations()" class="option hover:text-gray-900 block py-1 px-6">{{'headerMenu.textMyReservations' | translate}}</a>
            </li>
            <li>
         
              <a (click)="goToFavorites()"  class="option hover:text-gray-900 block py-1 px-6">{{'headerMenu.textMyFavourites' | translate}}</a>
            </li>
            <li>
              <a routerLink="services" class="text-gray-700 hover:text-gray-900 block py-1 px-6 option">{{'headerMenu.textServices' | translate}}</a>
            </li>
            <li>
              <a routerLink="us" class="text-gray-700 hover:text-gray-900 block py-1 px-6 option">{{'headerMenu.textUs' | translate}}</a>
            </li>
            <li *ngIf="isLogedin">
              <a (click)="openDialogCloseSession()" class="hover:text-gray-900 cursor-pointer block pt-1 pb-4 px-6 optionAction">{{'headerMenu.textLogOut' | translate}}</a>
            </li>
          </ul>
          <!-- <hr> -->
        </div>
      </div>
    </ng-container>
  </div>
</nav>
