import {BehaviorSubject, Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {ContactFormRepository} from "../repositories/contact-form.repository";
import {ServicesFormModel} from "../domain/services-form-model";
import {ContactFormModel} from "../domain/contact-form-model";

@Injectable({
    providedIn: 'root'
})
export class ContactFormUseCaseService {
    private telephonePrefixSource = new BehaviorSubject<string>('');
    currentTelephonePrefix = this.telephonePrefixSource.asObservable();

    constructor(private repository: ContactFormRepository) {
    }

    postEmailServices(form: Partial<ServicesFormModel>, captcha: string): Observable<any> {
        return this.repository.postEmailServices(form, captcha);
    }

    postEmailContact(form: Partial<ContactFormModel>, captcha: string): Observable<any> {
        return this.repository.postEmailContact(form, captcha);
    }

    postEmailSellerContact(postId: string, form: Partial<ContactFormModel>, captcha: string): Observable<any> {
        return this.repository.postEmailSellerContact(postId, form, captcha);
    }

    changeTelephonePrefix(prefix: string) {
        this.telephonePrefixSource.next(prefix);
    }

    postEmailReservation(postId: string, form: Partial<ContactFormModel>, captcha: string): Observable<any> {
        return this.repository.postEmailReservation(postId, form, captcha);
    }

    checkReservationPrice(accommodationId: string, nightPrice:string, fromDate:string, toDate:string):Observable<any>{
        return this.repository.checkReservationPrice(accommodationId,nightPrice,fromDate,toDate);
    }
}
