<div class="card-container">
    <ln-card-skeleton *ngIf="!isLoaded"></ln-card-skeleton>
    <app-card-reservation-state class="stateContainerCard" [location]="card.propiedad[0].direccion"
        *ngIf="state.length>0" [state]="card.estado" [idReserva]="card.idReserva"
        [fromDate]="card.from" [toDate]="card.to"
        ></app-card-reservation-state>
    <article class="card" (click)="navigateTo()">
        <aside>
            <img *ngIf="card.imagenes.length >0 " src={{card.imagenes[0].url.sm}} alt="" />
            <img *ngIf="card.imagenes.length === 0" class="imagenCard" alt="" />

            <div>
                <div *ngIf="card.propiedad[0].permiteMascotas" class="petFriendly-tag">
                    <div>
                        <div class="flex gap-1">
                            <p>Pet</p>
                            <img src="../../../../assets/icons/pets.svg" />
                        </div>
                        <p>Friendly</p>
                    </div>
                </div>

                <div class="image-overlay" (click)="markAsFavorite($event)">
                    <img *ngIf="isFavorite" src="../../../../assets/icons/favorite-post-like.svg" />
                    <img *ngIf="!isFavorite" src="../../../../assets/icons/favorite-post.svg" />

                </div>
            </div>
        </aside>


        <div class="info-container">
            <div class="card-header" *ngIf="state.length==0">
                <label *ngIf="card.PrecioCalculado===card.totalSinOferta"> {{ precioOfertaPorNoche | currency: 'USD':'symbol':'1.2-2'
                    }}</label>
                <div *ngIf="card.PrecioCalculado!==card.totalSinOferta" class="orangeNewPrice">
                    <p class="underlinedPrice">{{card.totalSinOferta | currency:
                        'USD':'symbol':'1.2-2'}}</p>
                        <p> &nbsp;Oferta {{ precioNocheCalculado | currency: 'USD':'symbol':'1.2-2'
                        }}</p>
                </div>
            </div>
            <div class="card-header" *ngIf="state.length>0">
                <label *ngIf="totalConTodo==0"> {{ precioNocheCalculado | currency: 'USD':'symbol':'1.2-2'
                }}</label>
            <div *ngIf="totalConTodo>0" class="orangeNewPrice">
                <p class="underlinedPrice">{{card.tarifas[0].precioNoche | currency:
                    'USD':'symbol':'1.2-2'}}</p>
                    <p> &nbsp;Oferta {{ totalConTodo | currency: 'USD':'symbol':'1.2-2'
                    }}</p>
            </div>
            </div>
            <div class="card-body">
                <div>
                    <!--En grande irá habitación y el nombre del bed and breakfast -->
                    <div class="accomodationTypeContainer">
                        <h1 *ngIf="!showDescription" class="accomodationType">{{accomodationType}}</h1>
                        <h1 *ngIf="showDescription" class="accomodationType">{{accomodationType}} {{card.nombre}}</h1>
                    </div>
                    <!--En pequeño irá el nombre del bed and breakfast -->
                    <div class="accomodationTypeContainer">
                        <p *ngIf="showDescription" class="bbType">{{card.propiedad[0].tipoPropiedad}}
                            {{card.propiedad[0].nombre}}</p>
                    </div>

                    <!-- <p *ngIf="card.format">{{card.format}}</p> -->
                </div>
                <div>
                    <span class="prices flex items-center">
                        {{ getLabel() }}
                    </span>
                    <!-- <div *ngIf="card.ofertas.length>0" class="nightPrice">

                        <p>{{'card.labelPricePerNight'|translate}}
                            <span style="text-decoration: line-through;">{{precioNocheCalculado | currency:
                                'USD':'symbol':'1.2-2'}}</span>
                            | {{'card.labelDiscount'|translate}} {{ precioOfertaPorNoche| currency:
                            'USD':'symbol':'1.2-2' }}
                        </p>
                    </div> -->
                    <div class="nightPrice">
                        <p>{{'card.labelPricePerNight'|translate}} {{card.tarifas[0].precioNoche | currency:
                            'USD':'symbol':'1.2-2'}}</p>
                    </div>

                    <p>{{'card.labelTaxes'|translate}}</p>
                </div>

                <!-- Cantidades a mostrar para casas y departamentos -->
                <div *ngIf="card.propiedad[0].tipoPropiedad !== 'BedBreakfast'" class="detailsRoom-container">
                    <div *ngIf="card.cantidadCamas>=0" class="flex items-center">
                        <img src="../../../../assets/icons/bedroom.svg" alt="" />
                        <p *ngIf="card.cantidadCamas<=1">{{card.cantidadCamas}} {{'card.labelBedroom'|translate}}</p>
                        <p *ngIf="card.cantidadCamas>1">{{card.cantidadCamas}} {{'card.labelBedrooms'|translate}}</p>
                    </div>
                    <div *ngIf="card.cantidadBanos>=0" class="flex items-center">
                        <img src="../../../../assets/icons/shower.svg" alt="" />
                        <p *ngIf="card.cantidadBanos<=1">{{card.cantidadBanos}} {{'card.labelBathroom'|translate}}</p>
                        <p *ngIf="card.cantidadBanos>1">{{card.cantidadBanos}} {{'card.labelBathrooms'|translate}}</p>
                    </div>
                </div>
                <!-- Tipos a mostrar para bed and breakfast -->
                <div *ngIf="card.propiedad[0].tipoPropiedad==='BedBreakfast'" class="detailsRoom-container">
                    <div class="flex items-center" *ngIf="card.cantidadCamas>=1">
                        <img src="../../../../assets/icons/bedroom.svg" alt="" />
                        <p>{{card.cantidadCamas}} {{bedType|translate}}</p>
                    </div>
                    <div class="flex items-center" *ngIf="card.cantidadCamas===0">
                        <img src="../../../../assets/icons/bedroom.svg" alt="" />
                        <p>{{card.cantidadCamas}} {{'card.labelBed'|translate}}</p>
                    </div>


                    <div class="flex items-center" *ngIf="card.cantidadBanos>=1">
                        <img src="../../../../assets/icons/shower.svg" alt="" />
                        <p>{{card.cantidadBanos}} {{bathroomType|translate}}</p>
                    </div>
                    <div class="flex items-center" *ngIf="card.cantidadBanos===0">
                        <img src="../../../../assets/icons/shower.svg" alt="" />
                        <p>{{card.cantidadBanos}} {{'card.labelBathroom'|translate}}</p>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="location-container">
                    <img src="../../../../assets/icons/location-solid.svg" alt="" />
                    <h2 class="location">{{card.propiedad[0].direccion}}</h2>
                </div>
                <div class="favorite">
                    <p>{{'card.textDistance1'|translate}} {{card.propiedad[0].distanciaAlCentro}} km
                        {{'card.textDistance2'|translate}}</p>
                    <div *ngIf="showDeleteFavorite" (click)="markAsFavorite($event)">
                        <img class="delete" src="../../../../assets/icons/delete.svg" />
                        <p class="texteDelete underlined">{{'card.textDelete'|translate}}</p>
                    </div>

                </div>

            </div>
        </div>
    </article>
</div>