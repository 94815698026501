import {TranslateService} from "@ngx-translate/core";

export class FilterSideMenuMeta {

    textData = {};
    constructor(private translate: TranslateService) {
        this.translate.get('filtersSideMenu').subscribe((data) => {
            this.textData = data;
        });
    }

    static readonly controlsKey: ControlsKey = {
        type: 'type',
        purpose: 'purpose',
        typeOfRent: 'typeOfRent',
        habitaciones: 'habitaciones',
        banos: 'banos',
        parking: 'parking',
        parkingDentro: 'parkingDentro',
        parkingFuera:'parkingFuera',
        condition: 'condition',
        tour: 'tour',
        vr: 'vr',
        postedToday: 'postedToday',
        permiteMascotas: 'permiteMascotas',
        terraza: 'terraza',
        jardin: 'jardin',
        grill: 'grill',
        areaTrabajo: 'areaTrabajo',
        wifi: 'wifi',
        piscina: 'piscina',
        smokerFriendly: 'smokerFriendly',
        lavadora: 'lavadora',
        secadora: 'secadora',
        cocina: 'cocina',
        nevera: 'nevera',
        tv: 'tv',
        microondas: 'microondas',
        balcon: 'balcon',
        calefaccion: 'calefaccion',
        aireAcondicionado: 'aireAcondicionado',
        sofa: 'sofa',
        tieneAscensor: 'tieneAscensor',
        secadorCabello: 'secadorCabello',
        plancha: 'plancha',
        detectorHumo: 'detectorHumo',
        detectorMonoxidoCarbono: 'detectorMonoxidoCarbono',
        camas:'camas',
        camaQueen: 'camaQueen',
        camaKing: 'camaKing',
        camaSimple: 'camaSimple',
        camaMatrimonial: 'camaMatrimonial',
        banoCompartido: 'banoCompartido',
        banoPrivado: 'banoPrivado',
        areaMeditacion: 'areaMeditacion',
        fumarEnHabitacion:'fumarEnHabitacion'
    }

    static readonly controlsKeyAdditionalServices = {
        tour: 'tour',
        vr: 'vr',
        postedToday: 'postedToday'
    }

    static readonly controlsKeyImmutables: ControlsKeyImmutables = {
        type: 'type',
        purpose: 'purpose'
    }

    static readonly typeOfRent: FilterValuesContainer = {
        name: 'filtersSideMenu.textTypeOfLease',
        filterValue: [
            {
                key: 0,
                value: 'filtersSideMenu.textTraditional',
                filterType: FilterSideMenuMeta.controlsKey.cocina,
            },
            {
                key: 1,
                value: 'Online',
                filterType: FilterSideMenuMeta.controlsKey.nevera,
            }
        ]
    }
    
    static readonly typeOfService: FilterValuesContainer = {
        name: 'OtherServices',
        filterValue: [
            {
                key: 0,
                value: 'filtersSideMenu.textTraditional',
                filterType: FilterSideMenuMeta.controlsKey.typeOfRent,
            },
            {
                key: 1,
                value: 'Online',
                filterType: FilterSideMenuMeta.controlsKey.typeOfRent,
            }
        ]
    }


    static readonly parking: FilterValuesContainer = {
        name: 'Parking',
        filterValue: [
            {
                key: 1,
                value: 'inside Parking',
                filterType: FilterSideMenuMeta.controlsKey.parkingDentro,
            },
            {
                key: 2,
                value: 'outside Parking',
                filterType: FilterSideMenuMeta.controlsKey.parkingFuera,
            },
        ]
    }

    
    static readonly otherServices: FilterValuesContainer = {
        name: 'filtersSideMenu.textOtherServices',
        filterValue: [
            {
                key: 1,
                value: 'filtersSideMenu.textWashingMachine',
                filterType: FilterSideMenuMeta.controlsKey.lavadora,
            },
            {
                key: 1,
                value: 'filtersSideMenu.textDryerMachine',
                filterType: FilterSideMenuMeta.controlsKey.secadora,
            },
            {
                key: 1,
                value: 'filtersSideMenu.textKitchen',
                filterType: FilterSideMenuMeta.controlsKey.cocina,
            },
            {
                key: 1,
                value: 'filtersSideMenu.textFridge',
                filterType: FilterSideMenuMeta.controlsKey.nevera,
            },
            {
                key: 1,
                value: 'filtersSideMenu.textTv',
                filterType: FilterSideMenuMeta.controlsKey.tv,
            },
            {
                key: 1,
                value: 'filtersSideMenu.textMicrowave',
                filterType: FilterSideMenuMeta.controlsKey.microondas,
            },
            {
                key: 1,
                value: 'filtersSideMenu.textBalcony',
                filterType: FilterSideMenuMeta.controlsKey.balcon,
            },
            {
                key: 1,
                value: 'filtersSideMenu.textTerrace',
                filterType: FilterSideMenuMeta.controlsKey.terraza,
            },
            {
                key: 1,
                value: 'filtersSideMenu.textHeating',
                filterType: FilterSideMenuMeta.controlsKey.calefaccion,
            },
            {
                key: 1,
                value: 'filtersSideMenu.textAirConditioner',
                filterType: FilterSideMenuMeta.controlsKey.aireAcondicionado,
            },
            {
                key: 1,
                value: 'filtersSideMenu.textSofa',
                filterType: FilterSideMenuMeta.controlsKey.sofa,
            },
            {
                key: 1,
                value: 'filtersSideMenu.textElevatorEntrance',
                filterType: FilterSideMenuMeta.controlsKey.tieneAscensor,
            },
            {
                key: 1,
                value: 'filtersSideMenu.textHairDryer',
                filterType: FilterSideMenuMeta.controlsKey.secadorCabello,
            },
            {
                key: 1,
                value: 'filtersSideMenu.textIron',
                filterType: FilterSideMenuMeta.controlsKey.plancha,
            },
            {
                key: 1,
                value: 'filtersSideMenu.textGrill',
                filterType: FilterSideMenuMeta.controlsKey.grill,
            },
            {
                key: 1,
                value: 'filtersSideMenu.textPool',
                filterType: FilterSideMenuMeta.controlsKey.piscina,
            },
            {
                key: 1,
                value: 'filtersSideMenu.textMeditationArea',
                filterType: FilterSideMenuMeta.controlsKey.areaMeditacion,
            },
            {
                key: 1,
                value: 'filtersSideMenu.textSmokerFriendly',
                filterType: FilterSideMenuMeta.controlsKey.fumarEnHabitacion,
            }
        ]
    }
    static readonly security: FilterValuesContainer = {
        name: 'filtersSideMenu.textSecurity',
        filterValue: [
            {
                key: 1,
                value: 'filtersSideMenu.textSmokeDetector',
                filterType: FilterSideMenuMeta.controlsKey.detectorHumo,
            },
            {
                key: 1,
                value: 'filtersSideMenu.textCarbonMonoxideDetector',
                filterType: FilterSideMenuMeta.controlsKey.detectorMonoxidoCarbono,
            }
        ]
    }

    static readonly workspace: FilterValuesContainer = {
        name: 'filtersSideMenu.textWorkspace',
        filterValue: [
            {
                key: 1,
                value: 'filtersSideMenu.textWorkspace',
                filterType: FilterSideMenuMeta.controlsKey.areaTrabajo,
            }
        ]
    }

    static readonly wifi: FilterValuesContainer = {
        name: 'filtersSideMenu.textWifi',
        filterValue: [
            {
                key: 1,
                value: 'filtersSideMenu.textWifi',
                filterType: FilterSideMenuMeta.controlsKey.wifi,
            }
        ]
    }


    static readonly petFriendly: FilterValuesContainer = {
        name: 'Pet friendly',
        filterValue: [
            {
                key: 1,
                value: 'Pet friendly',
                filterType: FilterSideMenuMeta.controlsKey.permiteMascotas,
            }
        ]
    }
    
    static readonly terrace: FilterValuesContainer = {
        name: 'filtersSideMenu.textTerrace',
        filterValue: [
            {
                key: 1,
                value: 'filtersSideMenu.textTerrace',
                filterType: FilterSideMenuMeta.controlsKey.terraza,
            }
        ]
    }

    static readonly garden: FilterValuesContainer = {
        name: 'filtersSideMenu.textGarden',
        filterValue: [
            {
                key: 1,
                value: 'filtersSideMenu.textGarden',
                filterType: FilterSideMenuMeta.controlsKey.jardin,
            }
        ]
    }

    static readonly pool: FilterValuesContainer = {
        name: 'filtersSideMenu.textPool',
        filterValue: [
            {
                key: 1,
                value: 'filtersSideMenu.textPool',
                filterType: FilterSideMenuMeta.controlsKey.piscina,
            }
        ]
    }
    
    static readonly boolParking: FilterValuesContainer = {
        name: 'filtersSideMenu.textParkingInTheAccommodation',
        filterValue: [
            {
                key: 1,
                value: 'filtersSideMenu.textParkingInTheAccommodation',
                filterType: FilterSideMenuMeta.controlsKey.parking,
            }
        ]
    }

}

interface ControlsKey {
    type: FormFilterType;
    purpose: FormFilterType;
    typeOfRent: FormFilterType;
    habitaciones: FormFilterType;
    banos: FormFilterType;
    condition: FormFilterType;
    tour: FormFilterType,
    vr: FormFilterType,
    postedToday: FormFilterType,
    permiteMascotas: FormFilterType,
    terraza: FormFilterType,
    jardin: FormFilterType,
    areaTrabajo: FormFilterType,
    wifi: FormFilterType,
    piscina: FormFilterType,
    grill: FormFilterType,
    smokerFriendly: FormFilterType,
    parking: FormFilterType,
    parkingDentro : FormFilterType,
    parkingFuera : FormFilterType,
    lavadora: FormFilterType,
    secadora: FormFilterType,
    cocina: FormFilterType,
    nevera: FormFilterType,
    tv: FormFilterType,
    microondas: FormFilterType,
    balcon: FormFilterType,
    calefaccion: FormFilterType,
    aireAcondicionado: FormFilterType,
    sofa: FormFilterType,
    tieneAscensor: FormFilterType,
    secadorCabello: FormFilterType,
    plancha: FormFilterType,
    detectorHumo: FormFilterType,
    detectorMonoxidoCarbono: FormFilterType,
    camas: FormFilterType,
    camaQueen: FormFilterType,
    camaKing: FormFilterType,
    camaSimple: FormFilterType,
    camaMatrimonial: FormFilterType,
    banoCompartido: FormFilterType,
    banoPrivado: FormFilterType,
    areaMeditacion: FormFilterType,
    fumarEnHabitacion: FormFilterType
}

interface ControlsKeyImmutables {
    type: string;
    purpose: string;
}

interface FilterValuesContainer {
    filterValue: FilterValues[];
    name: string;
}

export interface FiltersChips {
    formControl: string;
    id: number | string;
    isImmutable: boolean;
    value: string;
}

export interface FilterValues {
    [x: string]: any;
    cant?: number;
    filterType: FormFilterType;
    isSelected?: boolean;
    key: number;
    value: string;
    isDisabled?: boolean;
}

export type ControlsKeyImmutableType = 'type' | 'purpose';

//TABLA catalogoObjetos falta sofaCama. cama king, cama queen
//TABLA tipoServicio falta lavanderia, cocina
//TABLA tipoAmbiente añadir terraza? podria ser privada? balcon, areaMeditacion
//TABLA tipoServicio 


export type FormFilterType =
    'type'
    | 'purpose'
    | 'typeOfRent'
    | 'habitaciones'
    | 'banos'
    | 'condition'
    | 'tour'
    | 'vr'
    | 'postedToday'
    | 'permiteMascotas'
    | 'terraza'
    | 'grill'
    | 'jardin'
    | 'areaTrabajo'
    | 'wifi'
    | 'piscina'
    | 'smokerFriendly'
    | 'parking'
    | 'parkingDentro'
    | 'parkingFuera'
    | 'lavadora'
    | 'secadora'
    | 'cocina'
    | 'nevera'
    | 'tv'
    | 'microondas'
    | 'balcon'
    | 'calefaccion'
    | 'aireAcondicionado'
    | 'sofa'
    | 'tieneAscensor'
    | 'secadorCabello'
    | 'plancha'
    | 'detectorHumo'
    | 'detectorMonoxidoCarbono'
    | 'camas'
    | 'camaQueen'
    | 'camaKing'
    | 'camaSimple'
    | 'camaMatrimonial'
    | 'banoCompartido'
    | 'banoPrivado'
    | 'areaMeditacion'
    | 'fumarEnHabitacion'

export type PurposeType = 'Rent' | 'Buy';

export type FilterContainerType = FilterValuesContainer;
