import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bb-profile-contact',
  templateUrl: './bb-profile-contact.component.html',
  styleUrl: './bb-profile-contact.component.css'
})
export class BbProfileContactComponent {

  @Input() image:string='';
  @Input() bbName:string='';
  @Input() publications:number=0;
  @Input() bbId:number=0;

  constructor(
    private _router: Router,
  ){
  }

  contactForm(){
    this._router.navigate(["contact-page-form"], {

      //   queryParamsHandling: "merge",
    });
  }

}
