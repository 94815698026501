import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-failed-reservation',
  templateUrl: './failed-reservation.component.html',
  styleUrl: './failed-reservation.component.css'
})
export class FailedReservationComponent {

}
