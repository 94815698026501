import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslationService } from '../../services/translation.service';
import { Router } from '@angular/router';
import { ObjetosEntity } from '../../../data/entity/mapa/objetos-entity';
import { AuthService } from '../../services/auth.service';
import { DialogService } from '../../services/dialog.service';
import { AlojamientosPostUseCase } from '../../../core/usecase/alojamientos-post-use-case.service';
import { take } from 'rxjs';
import { RESERVATION_STATUS } from '../../../core/constants/reservations-status-contants';

@Component({
  selector: 'app-card-visited-reservation',
  templateUrl: './card-visited-reservation.component.html',
  styleUrl: './card-visited-reservation.component.css'
})
export class CardVisitedReservationComponent implements OnInit {

  constructor(
    private _translate: TranslationService,
    private _router: Router,
    private _auth: AuthService,
    private _dialogService: DialogService,
    private _postService: AlojamientosPostUseCase,
    private _cdr: ChangeDetectorRef,
  ) {

  }

  @Input() accommodationType: string = '';
  @Input() card:any;
  @Output() refreshEvent = new EventEmitter<void>();
  isFavorite:boolean=false;
  bedType: string = ''
  bathroomType: string = ''

  ngOnInit(): void {
    this.setAccomodationType();
  }

  goToDetails(){
    if(this.card.estado==RESERVATION_STATUS.CANCELLED){
      this._router.navigate(["post-details", this.card.idAlojamiento], {
  
        //   queryParamsHandling: "merge",
      });
    }
    else{
      this._router.navigate(["my-reservation-details", this.card.idReserva],{
        queryParams:{state:RESERVATION_STATUS.DONE}
      });
    }

  }

  markAsFavorite(event: Event){
    event.stopPropagation();
    if (this._auth.isLogedin) {
      this.card.idAlojamiento    
      this._postService.markAsFavorite(this.card.idAlojamiento)
        .pipe(take(1))
        .subscribe({
          next: () => {
              this.isFavorite = !this.isFavorite;
              this.triggerRefresh();
              this._cdr.detectChanges
          },
          error: () => {
            this.isFavorite = !this.isFavorite;
            this.triggerRefresh();
            this._cdr.detectChanges
          }
        });
    } else {
      this._dialogService.openLoginRegistSessionDialog()
        .pipe(take(1))
    }
  }

  triggerRefresh(): void {
    this.refreshEvent.emit();
  }


  setAccomodationType() {
    const descriptionToTranslationKey: { [key: string]: string } = {
      'Apartments': 'card.labelApartment',
      'Houses': 'card.labelHouse',
      'BedBreakfast': 'card.labelBedAndBreakFast',
      'Suite': 'card.labelSuite'
    };

    const bedTypesToTranslationKey: { [key: string]: string } = {
      "queenBed": "filtersSideMenu.textQueenBed",
      "kingBed": "filtersSideMenu.textKingBed",
      "singleBed": "filtersSideMenu.textIndividualBed",
      "matrimonialBed": "filtersSideMenu.textMatrimonialBed",
    };

    const propertyType = this.card.propiedad[0].tipoPropiedad;
    const translationKey = descriptionToTranslationKey[propertyType];
    this.isFavorite=this.card.favoritos==1?true:false

    if (translationKey === "card.labelBedAndBreakFast") {
    //  this.showDescription = true;

      let bathTranslationKey = '';
      let bedTranslationKey = '';
      

      // Loop through each environment in the property
      this.card.ambientes.forEach((element: any) => {
        if (element.codTipoAmbiente === "AMB1") {
          bathTranslationKey = 'filtersSideMenu.textPrivateBathroom';
        } else if (element.codTipoAmbiente === "AMB7") {
          bathTranslationKey = 'filtersSideMenu.textSharedBathroom';
        }

      });

      this.card.objetos.forEach((element: ObjetosEntity) => {
        const bedTypeDescription = element.idObjeto;
        if (bedTypeDescription in bedTypesToTranslationKey) {
          bedTranslationKey = bedTypesToTranslationKey[bedTypeDescription];
        }
      });

      // Translate bed type
      if (bedTranslationKey) {
        this._translate.getTranslation(bedTranslationKey).subscribe((res: string) => {
          this.bedType = res;
        });
      }

      // Translate bathroom type
      if (bathTranslationKey) {
        this._translate.getTranslation(bathTranslationKey).subscribe((res: string) => {
          this.bathroomType = res;
        });
      }
    }


    if (translationKey) {
      this._translate.getTranslation(translationKey).subscribe((res: string) => {
        this.accommodationType = res;
      });
    }
  }

}
