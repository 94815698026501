import {CityModel} from "../../core/domain/city-model";
import {createReducer, on} from '@ngrx/store';
import {CityApiActions} from './../index';

export const cityStateKey = 'cityState';

export interface CitiesState {
    cities: CityModel[]
}

const initialState: CitiesState = {
    cities: []
}

// Función encargada de hacer los cambios en el Store de la app 
export const citiesReducer = createReducer(
    initialState,
    on(CityApiActions.loadAllCities, (currentState, action) => ({
        ...currentState,
        cities: action.cities
    }))
)
