import { Injectable } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsLoaderService {
  private loader: Loader;
  private isApiLoaded: boolean = false;
  mapskey = environment.googleMapApiKey;
  constructor() {
    this.loader = new Loader({
      apiKey: this.mapskey,
      version: 'weekly',
      libraries: ['places']
    });
  }

  loadApi(): Promise<void> {
    if (this.isApiLoaded) {
      return Promise.resolve();
    }

    return this.loader.load().then(() => {
      this.isApiLoaded = true;
    });
  }
}