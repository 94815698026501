import {Component, EventEmitter, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Location} from "@angular/common";

import {Rubros} from "../../lists/rubros";
import {ServicesTypes} from "../../lists/services-types";
import {ContactFormUseCaseService} from "../../../core/usecase/contact-form-use-case.service";
import {DialogService} from "../../services/dialog.service";
import {ModalService} from "../../services/modal.service";
import {environment} from "../../../../environments/environment";

export interface ServiceForm {
    name: FormControl,
    phone: FormControl,
    email: FormControl,
    services: FormControl,
    copy: FormControl
}

@Component({
    selector: 'app-services-form',
    templateUrl: './services-form.component.html',
    styleUrls: ['./services-form.component.css']
})
export class ServicesFormComponent {

    @Output() contactData = new EventEmitter<Object>();
    servicesTypeList: any = [];
    rubros: any = [];
    formGroup: FormGroup<ServiceForm> = new FormGroup(
        {
            name: new FormControl('', Validators.required),
            phone: new FormControl('', [Validators.required, Validators.pattern("^[0-9]+$")]),
            services: new FormControl('', Validators.required),
            email: new FormControl('', [Validators.required, Validators.email]),
            copy: new FormControl(false)
        }
    );

    constructor(
        private _contactFormUseCaseService: ContactFormUseCaseService,
        private _modal: ModalService,
        private _dialogService: DialogService,
        private _location: Location
    ) {
        this.rubros = new Rubros().rubros.map(el => ({ key: el.name, value: el.es + ","+ el.en }));
        this.servicesTypeList = new ServicesTypes().services.map((el) => ({key: el.name, value: el.es + "," + el.en}));
    }

    async sendEmails() {
        const captcha: string = await new Promise((resolve, reject) => {
            window.turnstile.render('#captcha_turnstile', {
                sitekey: environment.recaptcha.publicKey,
                theme: 'light',
                callback: function (token: string) {
                    resolve(token)
                }
            })
        });

        this.contactData.emit({...this.formGroup.value, captcha});
    }

    checkBoxChanged(event: any) {
        this.formGroup.controls.copy.setValue(event.valueOf())
    }

    goBack() {
        this._location.back();
    }
}
