import { Component, OnInit } from '@angular/core';
import { TranslationService } from '../../services/translation.service';

@Component({
  selector: 'app-clear-button',
  templateUrl: './clear-button.component.html',
  styleUrl: './clear-button.component.css'
})
export class ClearButtonComponent implements OnInit {
  hoverText: string = '';
  currentlang: string | undefined;

  constructor(
    public _translate: TranslationService
  ) {}

  ngOnInit() {
    this._translate.onLangChange.subscribe(() => {
      this.currentlang= this._translate.getCurrentLang;
      this.updateText();
    });

    this.updateText();
  }

  updateText() {
    if (this._translate.getCurrentLang === 'es') {
      this.hoverText = 'Limpiar filtros';
    } else if (this._translate.getCurrentLang === 'en') {
      this.hoverText = 'Clear filters';
    }
  }
}
