import { ChangeDetectorRef, Component, Input, inject, input } from '@angular/core';
import { StoreFiltersService } from '../../services/storeFilters.service';
import { TranslationService } from '../../services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ServiceHintComponent } from '../service-hint/service-hint.component';
import { Router } from '@angular/router';
import { FirebaseService } from '../../services/firebase.service';
import { AuthService } from '../../services/auth.service';
import { DialogService } from '../../services/dialog.service';
import { take } from "rxjs";
import { calcularPrecioTotalConTarifas, calcularTotalConArrayDescuento, calcularTotalNoches } from '../../helpers/helpers';
import { Bloqueos } from '../../interfaces/bloqueos.interface';
import { OfertaModel } from '../../../core/domain/property-detail-post-model';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-booking-card',
  templateUrl: './booking-card.component.html',
  styleUrl: './booking-card.component.css'
})
export class BookingCardComponent {

  @Input() propertyId: string = "";
  @Input() maxGuests: number = 50;
  @Input() oferta: number = 0;
  @Input() arrayOferta:OfertaModel[]=[]
  @Input() nightPrice: number = 1;
  @Input() fechasBloqueo: Bloqueos[] = [];
  textBooking: string = '';
  @Input() tarifaLimpieza: number = 1;
  cantidadNoches: number = 1;
  precioTotalNoches: number = 0;
  tarifaLunaNueva: number = 1;
  total: number = 0;
  porcentajeLunaNueva = 0.03;
  showAlert = false;
  esp: boolean = true;
  currentLanguage = "es";
  hoverText: string | undefined;
  fromDate: any;
  toDate: any;
  precioNocheOferta: number = 0;
  isLogin = this._firebaseService.isAuthenticatedUser();
  totalConTodo:number=0;


  constructor(
    private storeFilters: StoreFiltersService,
    private _translationService: TranslationService,
    private _translateService: TranslateService,
    private _cdr: ChangeDetectorRef,
    private _router: Router,
    private _firebaseService: FirebaseService,
    private _auth: AuthService,
    private _dialogService: DialogService,

  ) {
    _translateService.onLangChange.subscribe(() => {

      this.currentLanguage = this._translationService.getCurrentLang;
      if (this.currentLanguage === 'en')
        this.esp = false;
      else {
        this.esp = true;
      }
      this.toggleMessage();
      this.getText();
    });


  }
  ngOnInit(): void {
    setTimeout(() => {
      this.fromDate = this.storeFilters.getFilter().fromDate;
      this.toDate = this.storeFilters.getFilter().toDate;
    }, 200);

    setTimeout(() => {
      this.getCantidadNoches();
      this.calcularPrecioTotal();
    }, 500);


  }

  gotToReservation() {
    if (this._auth.isLogedin) {

      this._router.navigate(["reservation", this.propertyId], {
        //   queryParamsHandling: "merge",
      });
      this.storeFilters.setFilter({ fromDate: this.fromDate, toDate: this.toDate });
    } else {
      this._dialogService.openLoginRegistSessionDialog('', this._router.url)
        .pipe(take(1))
    }

  }

  onDateRangeChanged($event: any) {

 
    if($event.fromDate !== null && $event.toDate !== null){
      const from = this.convertNgbDateToDate($event.fromDate);
      this.fromDate = this.convertDateToString(from);

      const toDate = this.convertNgbDateToDate($event.toDate);

      this.toDate = this.convertDateToString(toDate);
    }
    // if (this.fromDate!==this.storeFilters.getFilter().fromDate) {
    //   this.fromDate = this.storeFilters.getFilter().fromDate;
    // }

    // if (this.toDate !== this.storeFilters.getFilter().toDate) {
    //   this.toDate = this.storeFilters.getFilter().toDate;
    // }

    this.getCantidadNoches();
    this.calcularPrecioTotal();
    this._cdr.detectChanges();
  }

  calcularPrecioTotal() {

    this.precioNocheOferta = calcularTotalNoches(this.nightPrice, null, this.cantidadNoches);

    this.precioTotalNoches = this.precioNocheOferta;

    const start=this.getDateFormat(this.fromDate);
    const end= this.getDateFormat(this.toDate);
    const result= calcularTotalConArrayDescuento(this.arrayOferta, this.nightPrice, new Date(start).toISOString(), new Date(end).toISOString());
    this.totalConTodo=0;


    if(result.total>0){
      //Hay descuento, por lo que retorna el total de noches que tienen descuento con su valor final
      if(result.nochesTotales>=this.cantidadNoches){
        //Todas las noches seleccionadas por el usuario tienen descuento, esto da el total
        this.totalConTodo=result.total;
      }
      if(result.nochesTotales>this.cantidadNoches){
        //Hay menos noches seleccionadas por el usuario que noches de descuentos

      }
      if(result.nochesTotales<this.cantidadNoches){
        //Hay mas noches en el rango seleccionado por el usuario que en el arreglo de ofertas. Se calculan las noches regulares como siempre.
        //Las noches con ofertas vienen de result.total por lo que se suman con las sin descuento para el total
        const partialValueNoDiscount=calcularTotalNoches(this.nightPrice, null, (this.cantidadNoches-result.nochesTotales));
        this.totalConTodo = partialValueNoDiscount+result.total;
      }
    }

    if(result.total==0){
      //No hay descuento. Null a oferta
      this.precioNocheOferta = calcularTotalNoches(this.nightPrice, null, this.cantidadNoches);
      this.precioTotalNoches = this.precioNocheOferta;
    }

    this.getTotal(this.precioTotalNoches);
    this._cdr.detectChanges();
  }

  getTotal(totalNights: number) {
    //Se incluye la tarifa de limpieza?
    this.tarifaLunaNueva = (totalNights + 0) * this.porcentajeLunaNueva;
    this.tarifaLunaNueva = parseFloat(this.tarifaLunaNueva.toFixed(1));
    this.total = calcularPrecioTotalConTarifas(totalNights, this.tarifaLunaNueva, this.tarifaLimpieza)
    if(this.totalConTodo>0){
    this.totalConTodo= this.totalConTodo+this.tarifaLunaNueva+Number(this.tarifaLimpieza)
    }
    return this.total;
  }

  getDateFormat(date:string){
    const [year, month, day] = date.split('-').map(Number);
    return new Date(year, month - 1, day);
  }

  getCantidadNoches() {
    // Convertir las cadenas de texto a objetos Date
    let checkIn = new Date(this.fromDate);
    let checkOut = new Date(this.toDate);

    // Calcular la diferencia de tiempo en milisegundos
    const diffTime = Math.abs(checkOut.getTime() - checkIn.getTime());

    // Convertir la diferencia de tiempo en días y redondear hacia arriba
    this.cantidadNoches = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }

  getText() {
    this._translationService.getTranslation('card.textBooking').subscribe((data) => {
      return this.textBooking = data;
    });
  }

  toggleMessage() {
    if (this.currentLanguage === 'es') {
      this.hoverText = 'En Luna Nueva destinamos una parte de nuestros recursos para apoyar a artesanos y artistas independientes de Guatemala, brindándoles capacitación y soporte para promover sus productos alrededor del mundo.';
    } else if (this.currentLanguage === 'en') {
      this.hoverText = 'At Luna Nueva we allocate part of our resources to support artisans and independent artists from Guatemala, providing them with training and support to promote their products around the world.';
    } else {
      this.hoverText = undefined;
    }
  }


  private _matDialog = inject(MatDialog);
  showHint() {
    this._matDialog
      .open(ServiceHintComponent, {
        panelClass: 'app-service-hint',
      })
  }


  convertNgbDateToDate(ngbDate: NgbDate): Date {    
    return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
  }

  convertDateToString(date: Date): string {
    return date.toISOString().split('T')[0]; // Formato YYYY-MM-DD
  }
}
