import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CaptchaService {

  private captchaContainerId: string = '#captcha_turnstile';

  async getCaptchaToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      const existingCaptcha = document.querySelector(this.captchaContainerId);
      if (existingCaptcha) {
        existingCaptcha.innerHTML = '';
      }
      window.turnstile.render(this.captchaContainerId, {
        sitekey: environment.recaptcha.publicKey,
        theme: 'light',
        callback: (token: string) => {
          resolve(token);
        },
      });
    });
  }

  resetCaptcha(): void {
    const captchaContainer = document.querySelector(this.captchaContainerId);
    if (captchaContainer) {
      captchaContainer.innerHTML = '';
    }
  }
}
