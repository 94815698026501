import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslationService } from '../../services/translation.service';
import { environment } from '../../../../environments/environment';
import { Loader } from "@googlemaps/js-api-loader";
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TipoPropiedad } from '../../../core/domain/mapa/tipo-propiedad-model';
import { CatalogoObjetos } from '../../../data/entity/mapa/alojamientos-filters-response';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { AlojamientosFilters } from '../../interfaces/alojamientos-filters.interface';
import { StoreFiltersService } from '../../services/storeFilters.service';
import { ObjetosEntity } from '../../../data/entity/mapa/objetos-entity';
import { calcularPrecioTotalConTarifas, calcularTotalConArrayDescuento, calcularTotalNoches, contarNoches } from '../../helpers/helpers';
import { AuthService } from '../../services/auth.service';
import { DialogService } from '../../services/dialog.service';
import { AlojamientosPostUseCase } from '../../../core/usecase/alojamientos-post-use-case.service';
import { take } from 'rxjs';
import { ToastService } from '../../services/toast.service';
import { RESERVATION_STATUS } from '../../../core/constants/reservations-status-contants';

export enum tipoPropiedad {
  BedBreakfast = 'BedBreakfast',
  Apartments = 'Apartments',
  Houses = 'Houses'
}

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.css', './2cards.component.css']
})
export class CardsComponent implements OnInit, OnChanges {
  isLoaded = true
  accomodationType: string = ''
  bedType: string = ''
  bathroomType: string = ''
  currentLang: string | undefined;
  precioOferta: number = 0
  precioNocheCalculado: number = 0
  cantidadHuespedes: number = 0
  noches: number = 0
  isFavorite: boolean = false;
  showDescription: boolean = false;
  precioOfertaPorNoche: number = 0;
  totalConTodo: number = 0;
  //TODO REVISAR EN EL NGONINIT SI ES FAVORITO DEL USUARIO

  filterApplied: AlojamientosFilters = {} as AlojamientosFilters;

  //Asignarlo a la card por el hecho de haber filtrado Y clickeado para llevarlo al detalle?
  Adults: number = 0;
  Pets: number = 0;
  Infants: number = 0;
  Children: number = 0;
  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;
  rangeValues = '';
  @Input() card: any;
  @Input() showDeleteFavorite: boolean = false;
  @Input() state: string = "";
  @Input() idReserva: string = "";
  @Output() refreshEvent = new EventEmitter<void>();
  porcentajeLunaNueva = 0.03;


  constructor(
    private _translate: TranslationService,
    private translateService: TranslateService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _storeFilterService: StoreFiltersService,
    private _auth: AuthService,
    private _dialogService: DialogService,
    private elementRef: ElementRef,
    private _postService: AlojamientosPostUseCase,
    private _cdr: ChangeDetectorRef,
    private toastService: ToastService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this._changeDetectorRef.detectChanges();
  }

  ngOnInit() {
    this._translate.onLangChange.subscribe(() => {
      this.updateText();
    });
    this.updateText();
    this.getGuests();
    this.getDays();
    this.precioNocheCalculado = calcularTotalNoches(this.card.tarifas[0].precioNoche, null, this.noches);

    if (this.state.length > 0) {
      this.adjustContainerHeight();
    }
    if (this.state.length == 0) {
      //EL ENDPOINT DE RESERVAS NO TIENE ESTE VALOR.
      this.precioNocheCalculado = this.card.PrecioCalculado===0 ? calcularTotalNoches(this.card.tarifas[0].precioNoche, null, this.noches) : this.card.PrecioCalculado;
    }
  

    this.precioOfertaPorNoche = this.card.totalSinOferta === 0 ? this.card.tarifas[0].precioNoche : this.card.totalSinOferta;

    this._changeDetectorRef.detectChanges();
    this.isFavorite = this.card.favorito === 1 ? true : this.showDeleteFavorite ? true : false;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if(this.state.length>0){
      this.adjustContainerHeight();
    }
  }

  //@Output() searchMapButtonClicked = new EventEmitter<void>();
  navigateTo() {

    if (this.state.length == 0) {
      //Card normal voy al detalle de la pulbicacion
      let adults = this._activatedRoute.snapshot.queryParams['Adults'];
      let children = this._activatedRoute.snapshot.queryParams['Children'];
      let fromDate = this._activatedRoute.snapshot.queryParams['fromDate'];
      let toDate = this._activatedRoute.snapshot.queryParams['toDate'];


      this._storeFilterService.setFilter({ Adults: adults, Children: children });
      this._storeFilterService.setFilter({ fromDate: fromDate, toDate: toDate });

      this._setFilters();
      this._router.navigate(["post-details", this.card.idAlojamiento], {

        //   queryParamsHandling: "merge",
      });
    }
    else if (this.state == RESERVATION_STATUS.CONFIRMED) {
      this._router.navigate(["my-reservation-details", this.card.idReserva], {

        //   queryParamsHandling: "merge",
      });
    }
    else {
      this._router.navigate(["post-details", this.card.idAlojamiento], {

        //   queryParamsHandling: "merge",
      });
    }




    // this.searchMapButtonClicked.emit();
  }

  setAccomodationType() {
    const descriptionToTranslationKey: { [key: string]: string } = {
      'Apartments': 'card.labelApartment',
      'Houses': 'card.labelHouse',
      'BedBreakfast': 'card.labelBedAndBreakFast',
      'Suite': 'card.labelSuite'
    };

    const bedTypesToTranslationKey: { [key: string]: string } = {
      "queenBed": "filtersSideMenu.textQueenBed",
      "kingBed": "filtersSideMenu.textKingBed",
      "singleBed": "filtersSideMenu.textIndividualBed",
      "matrimonialBed": "filtersSideMenu.textMatrimonialBed",
    };

    // Check if property type is Bed and Breakfast
    const propertyType = this.card.propiedad[0].tipoPropiedad;
    const translationKey = descriptionToTranslationKey[propertyType];
    if (translationKey === "card.labelBedAndBreakFast") {
      this.showDescription = true;

      let bathTranslationKey = '';
      let bedTranslationKey = '';

      // Loop through each environment in the property
      this.card.ambientes.forEach((element: any) => {
        if (element.codTipoAmbiente === "AMB1") {
          bathTranslationKey = 'filtersSideMenu.textPrivateBathroom';
        } else if (element.codTipoAmbiente === "AMB7") {
          bathTranslationKey = 'filtersSideMenu.textSharedBathroom';
        }

      });

      this.card.objetos.forEach((element: ObjetosEntity) => {
        const bedTypeDescription = element.idObjeto;
        if (bedTypeDescription in bedTypesToTranslationKey) {
          bedTranslationKey = bedTypesToTranslationKey[bedTypeDescription];
        }
      });

      // Translate bed type
      if (bedTranslationKey) {
        this._translate.getTranslation(bedTranslationKey).subscribe((res: string) => {
          this.bedType = res;
        });
      }

      // Translate bathroom type
      if (bathTranslationKey) {
        this._translate.getTranslation(bathTranslationKey).subscribe((res: string) => {
          this.bathroomType = res;
        });
      }
    }

    // Translate accommodation type
    if (translationKey) {
      this._translate.getTranslation(translationKey).subscribe((res: string) => {
        this.accomodationType = res;
      });
    }
  }

  getDiscounts() {
    const start = this.getDateFormat(this._storeFilterService.getFilter().fromDate);
    const end = this.getDateFormat(this._storeFilterService.getFilter().toDate);
    let startD = new Date(start);
    let endD = new Date(end);
    //  console.log('rango filtros ', startD.toISOString(), endD.toISOString())
    //  console.log('ofertas ', this.card.ofertas)
    const result = calcularTotalConArrayDescuento(this.card.ofertas, this.card.tarifas[0].precioNoche, startD.toISOString(), endD.toISOString());
    this.totalConTodo = 0;
    if (result.total > 0) {
      //Hay descuento, por lo que retorna el total de noches que tienen descuento con su valor final
      if (result.nochesTotales >= this.noches) {
        // if(result.nochesTotales<this.noches){
        //   this.totalConTodo=(result.total/result.nochesTotales)*this.noches;
        // }
        // else{
        //Todas las noches seleccionadas tienen descuento, esto da el total
        this.totalConTodo = result.total;
        // }
      }

      if (result.nochesTotales < this.noches) {
        //Quedan noches para calcular el total. Se debe sumar la diferencia de noches por precio
        const partialValueNoDiscount = calcularTotalNoches(this.card.tarifas[0].precioNoche, null, (this.noches - result.nochesTotales));
        this.totalConTodo = partialValueNoDiscount + result.total;
      }
    }
  }

  getReservationDiscounts() {
    //NO USAMOS LOS FILTROS. USAMPOS LOS VALORES DEL PRECIO POR NOCHE DE LA RESERVA
    this.totalConTodo = 0;
    //Noches de la reserva
    const totalNights = contarNoches(this.card.from, this.card.to);
    //Precio total sin considerar ofertas
    const precioTotalSinOfertas = calcularTotalNoches(this.card.tarifas[0].precioNoche, null, totalNights);
    this.precioNocheCalculado = precioTotalSinOfertas;

    //Tarifa luna nueva es un porcentaje(3%) del total de todas las noches.
    let tarifaLunaNueva = precioTotalSinOfertas * this.porcentajeLunaNueva;
    tarifaLunaNueva = parseFloat(tarifaLunaNueva.toFixed(1));


    let total = calcularPrecioTotalConTarifas(precioTotalSinOfertas, tarifaLunaNueva, this.card.tarifas[0].costoLimpieza)
    total = Math.round(total);
    // console.log('total ', total, precioTotalSinOfertas, this.card.tarifas[0].costoTotal)
    if (total == this.card.tarifas[0].costoTotal) {
      //No hubo oferta
      this.precioNocheCalculado = total;
    }
    else {
      //Hubo oferta. Mostramos que hubo una oferta y lo que pagó
      this.totalConTodo = this.card.tarifas[0].costoTotal;
    }

  }


  getDateFormat(date: string) {
    const [year, month, day] = date.split('-').map(Number);
    return new Date(year, month - 1, day);
  }

  getGuests() {
    if (this._storeFilterService.getFilter().Children) {
      this.Children = parseInt(this._storeFilterService.getFilter().Children);
    }
    if (this._storeFilterService.getFilter().Adults) {
      this.Adults = parseInt(this._storeFilterService.getFilter().Adults);
    }
    this.cantidadHuespedes = this.Adults + this.Children;
    this._changeDetectorRef.detectChanges();
  }

  getDays() {
    this.fromDate = this._storeFilterService.getFilter().fromDate;
    this.toDate = this._storeFilterService.getFilter().toDate;

    if (this.fromDate && this.toDate) {
      // convertir strings a fechas de javaScript
      let checkIn = this.convertStringToDate(this._storeFilterService.getFilter().fromDate);
      let checkOut = this.convertStringToDate(this._storeFilterService.getFilter().toDate);
      // calcular la diferencia en milisegundos
      const diffTime = Math.abs(checkOut.getTime() - checkIn.getTime());

      // calcular la diferencia en noches redondeando hacia arriba
      this.noches = Math.ceil(diffTime / (1000 * 60 * 60 * 24));


      // Asegurarse que tiene al menos una noche
      if (isNaN(this.noches) || this.noches <= 0) {
        console.error("Error: Invalid date difference calculation. Defaulting to 1 night.");
        this.noches = 1;
      }
    } else {
      // por si no hay fechas
      console.error("Error: Invalid or missing dates. Defaulting to 1 night.");
      this.noches = 1;
    }
    //OBTENEMOS LOS DIAS Y LUEGO LOS DESCUENTOS, PERO SI ES UNA RESERVA ?
    if (this.state.length == 0) {
      this.getDiscounts();
    }
    else {
      this.getReservationDiscounts()
    }

  }


  convertStringToDate(fechaString: string): Date {
    const [year, month, day] = fechaString.split('-').map(Number);
    return new Date(year, month - 1, day);
  }

  updateText() {
    this._translate.getTranslation(this._translate.getCurrentLang).subscribe(() => {
      this.currentLang = this._translate.getCurrentLang;
      this.setAccomodationType();
    });
  }


  getLabel(): string {
    let label = this.translateService.instant('card.labelPrice');
    label += this.translateService.instant('card.labelFor');
    label += ' ';
    label += this.noches === 1 ? (this.noches + ' ' + this.translateService.instant('card.labelNight')) : (this.noches + ' ' + this.translateService.instant('card.labelNights'));
    label += ', ';
    label += this.card.maximoHuespedes === 1 ? (this.noches + ' ' + this.translateService.instant('card.labelGuest')) : (this.cantidadHuespedes + ' ' + this.translateService.instant('card.labelGuests'));
    return label;
  }

  markAsFavorite(event: any) {
    event.stopPropagation();
    if (this._auth.isLogedin) {
      this.card.idAlojamiento
      this._postService.markAsFavorite(this.card.idAlojamiento)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.isFavorite = !this.isFavorite;
            this.triggerRefresh();
            this._cdr.detectChanges
          },
          error: () => {
            this.isFavorite = !this.isFavorite;
            this.triggerRefresh();
            this._cdr.detectChanges
          }
        });
    } else {
      this._dialogService.openLoginRegistSessionDialog()
        .pipe(take(1))
    }
  }

  triggerRefresh(): void {
    this.refreshEvent.emit();
  }

  convertNgbDateToString(date: NgbDate): string {
    return `${date.year}-${date.month}-${date.day}`;
  }

  private _setFilters() {

    if (this.fromDate && this.toDate) {
      this.filterApplied.fromDate = this.convertNgbDateToString(this.fromDate);
      this.filterApplied.toDate = this.convertNgbDateToString(this.toDate);
    }
    if (!this._activatedRoute.snapshot.queryParams['permiteMascotas'] && this.Pets >= 1) {
      this.filterApplied.permiteMascotas = 1;
    }
    else if (this._activatedRoute.snapshot.queryParams['permiteMascotas'] && this.Pets == 0) {
      this.filterApplied.permiteMascotas = 0;
    }

    //this.storeFilterService.setFilter(this.searchMapFilters);
  }
  toastMessage: string = "";
  showSuccess() {
    this.toastService.showToast(this.toastMessage, 'success', 'toast-top-right');
  }
  adjustContainerHeight(): void {
    const cardContainer = this.elementRef.nativeElement.querySelector('.card-container') as HTMLElement;
    const card = this.elementRef.nativeElement.querySelector('.card') as HTMLElement;
    if (cardContainer) {
      let newHeight = 370;
      if (window.innerWidth < 600) {
        newHeight = 440;
      } else {
        newHeight = 370;
      }
      cardContainer.style.height = `${newHeight}px`;
      card.style.height = `${77}%`

    }
  }


}
function stopPropagation() {
  throw new Error('Function not implemented.');
}

