import {createFeatureSelector, createSelector} from '@ngrx/store';
import {CitiesState, cityStateKey} from './city.reducers';

/**
 * Este archivo define dos selectores utilizando @ngrx/store: uno para acceder al estado de las ciudades (cityState)
 *  y otro para obtener la lista de ciudades del estado de las ciudades (cities). 
 */

const cityState = createFeatureSelector<CitiesState>(cityStateKey);

/**
 * La función de proyección se ejecuta cada vez que el selector cityState emite un nuevo valor, 
 * y se utiliza para mapear el estado de las ciudades al valor que queremos seleccionar, 
 * en este caso, la lista de ciudades.
 */
export const cities = createSelector(
    cityState,
    (cityState) => cityState?.cities
)
