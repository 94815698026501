import { Component, Input, OnInit, ViewChild, inject, ElementRef, Output, EventEmitter, OnDestroy, AfterViewInit, } from '@angular/core';
import { PropertyTypeEntity } from '../../../data/entity/property-type-entity';
import { Observable, Subscription, switchMap, startWith, take, map, takeUntil, Subject, max } from 'rxjs';
import { CityUseCaseService } from '../../../core/usecase/city-use-case.service';
import { PropertyTypeUseCaseService } from '../../../core/usecase/property-type-use-case.service';
import { FirebaseService } from '../../services/firebase.service';
import { CityModel } from '../../../core/domain/city-model';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { FiltersInterface, sideFilters } from '../../interfaces/filters.interface';
import { Store } from '@ngrx/store';
import { CityPageActions, CitySelectors, PropertyTypesPageActions, } from './../../../ngrx/index';
import { MatDialog } from '@angular/material/dialog';
import { CurrencyPipe } from '@angular/common';
import { TranslationService } from '../../services/translation.service';
import Swiper from 'swiper';
import { FiltersSideMenuComponent } from '../../../views/nav/filters-side-menu/filters-side-menu.component'
import { TypeFilterService } from '../../../core/services/type-filter.service';
import { IsPetService } from '../../services/isPet.service';
import { AlojamientosFilters } from '../../interfaces/alojamientos-filters.interface';
import { StoreFiltersService } from '../../services/storeFilters.service';
import { BreakPointService } from "../../services/break-point.service";

@Component({
  selector: 'app-search-bar-mobile',
  templateUrl: './search-bar-mobile.component.html',
  styleUrls: ['./search-bar-mobile.component.css', './2search-bar-mobile.component.css', './3search-bar-mobile.component.css', './4search-bar-mobile.component.css'],
})
export class SearchBarMobileComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("togglePrice") togglePrice: ElementRef = {} as ElementRef;
  @ViewChild("showRangeDiv") menu: ElementRef = {} as ElementRef;
  xpandStatus = false;

  private _matDialog = inject(MatDialog);
  @Input() back: string = '/';
  @Input() label: string = '';

  @Input() selectedMinValue: number = 10_000;
  @Input() selectedMaxValue: number = 900_000;
  @Input() filters: FiltersInterface = {};
  subscriptions: Subscription[] = [];

  cities$!: Observable<CityModel[]>;
  propertyTypes$!: Observable<PropertyTypeEntity[]>;

  selectedValue: string = '';
  selectedLabel: string = '';

  Adults: number = 1;
  Pets: number = 0;
  Infants: number = 0;
  Children: number = 0;
  defaultPrice: boolean = true;

  changedDefaultValues: boolean = false;
  panelOpenState = false;
  value: number = 0;
  minPrice: number = 50;
  maxPrice: number = 1000;
  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;
  rangeValues = '';
  reset: boolean = false;


  swiper: Swiper | undefined;
  showRange: boolean = false;

  fontStyle?: string;

  typeAccomodationFilter: string = ''
  typeAccomodationService: TypeFilterService = inject(TypeFilterService);
  searchMapFilters: AlojamientosFilters = {} as AlojamientosFilters;

  isPetSelected: boolean = false;
  private destroy$ = new Subject<void>();
  take!: number;
  page!: number;
  sideFilters: sideFilters | undefined;

  constructor(
    private _cityService: CityUseCaseService,
    private _propertyTypeService: PropertyTypeUseCaseService,
    private firebaseService: FirebaseService,
    private _router: Router,
    private _store: Store,
    private currencyPipe: CurrencyPipe,
    private storeFilterService: StoreFiltersService,
    private translate: TranslationService,
    private _activatedRoute: ActivatedRoute,
    private petService: IsPetService,
    private breakPointService: BreakPointService
  ) { }

  ngOnInit() {
    this._getCities();
    this._getPropertyTypes();
    this.getFilters();
    this._setFilters();
    this.swiperConfig();
    const queryParams = this._activatedRoute.snapshot.queryParams;
    this.fontStyle = queryParams["type"] as string;
    this.storeFilterService.setFilter({ type: this.fontStyle });


    this.petService.petSelected$.pipe(takeUntil(this.destroy$)).subscribe(isPetSelected => {
      this.isPetSelected = isPetSelected
    });
    this.isPetSelected = this.petService.pet
    this.detectBoundariesChange();
  }

  ngOnDestroy() {
    if (this.subscriptions)
      this.subscriptions.forEach((subs) => subs.unsubscribe());
    this.destroy$.next();
    this.destroy$.complete();

  }
  loadSearchAmountParams(): void {
    const suscription = this._activatedRoute.queryParams.subscribe(params => {
      this.searchMapFilters = {};

      if (params["minPrice"] != undefined) {
        const minPrice = parseInt(params["minPrice"]);
        this.setMinValue(minPrice);

      }

      if (params["maxPrice"] != undefined) {
        const maxPrice = parseInt(params["maxPrice"]);
        this.setMaxValue(maxPrice);
      }

    });

    this.subscriptions.push(suscription);
  }


  swiperConfig() {
    this.swiper = new Swiper(".swiper-search", {
      direction: "horizontal",
      slidesPerView: "auto",
      spaceBetween: 20,
      pagination: {
        el: ".swiper-pagination-search",
        clickable: true,
        bulletClass: "swiper-pagination-bullet-search",
        bulletActiveClass: "swiper-pagination-bullet-active-search",
      }
    });
  }

  ngAfterViewInit() {
    this.setMaxValue(this.maxPrice);
    this.setMinValue(this.minPrice);
  }

  showRangePrice() {
    this.showRange = !this.showRange;
  }

  setMinValue(value: number) {
    this.minPrice = value;
    this.selectedMinValue = value;
    this.changedDefaultValues = true;
    this.defaultPrice = false;
    this.storeFilterService.setFilter({ minPrice: value });
  }

  setMaxValue(value: number) {
    this.maxPrice = value;
    this.selectedMaxValue = value;
    this.changedDefaultValues = true;
    this.defaultPrice = false;
    this.storeFilterService.setFilter({ maxPrice: value });
  }



  @Output() searchMapButtonClicked = new EventEmitter<void>();
  goToSearchInMap() {
    //this.mapService.triggerSearchInMap();
    this._setFilters();
    const filters = this.storeFilterService.getAnyFilter();
    this._router.navigate(["search-map"], {
      queryParams: {
        ...filters,
        changedDefaultValues: this.changedDefaultValues,
      },
    });

    this.searchMapButtonClicked.emit();
  }

  @Output() searchButtonClicked = new EventEmitter<void>();
  search() {
    this._setFilters();

    // Remove unwanted properties
    delete this.searchMapFilters["north"];
    delete this.searchMapFilters["south"];
    delete this.searchMapFilters["west"];
    delete this.searchMapFilters["east"];

    const query = this.storeFilterService.getAnyFilter();

    this._router.navigate(['/'], {
      queryParams: {
        ...query,
        changedDefaultValues: this.changedDefaultValues,
      },

    });

    this.searchButtonClicked.emit();
  }

  checkCurrentUrl() {
    const currentUrl = this._router.url; // Obtiene la URL actual
    if (currentUrl === '/search-map' && this.breakPointService.isMobile) {
      this.xpandStatus = true;
    }

  }

  private _setFilters() {
    let max
    let min
    let fromDate
    let toDate
    let permiteMascotas
    if (this.selectedMinValue >= 0 && this.changedDefaultValues) {
      min = this.selectedMinValue;
    }
    if (this.selectedMaxValue >= 0 && this.changedDefaultValues) {
      max = this.selectedMaxValue;
    }
    if (this.fromDate && this.toDate) {
      fromDate = this.convertNgbDateToString(this.fromDate);
      toDate = this.convertNgbDateToString(this.toDate);
    }

    if (this.Pets >= 1) {
      permiteMascotas = 1;
    }
    else if (this._activatedRoute.snapshot.queryParams['permiteMascotas'] && this.Pets == 0) {
      permiteMascotas = 0;
    }
   
    const filters = {
      page: this.page,
      take: this.take,
      fromDate: this.searchMapFilters.fromDate || '',
      toDate: this.searchMapFilters.toDate || '',
      permiteMascotas: this.searchMapFilters.permiteMascotas || 0,
      Adults: this.Adults,
      Children: this.Children,
      Infants: this.Infants,
      Pets: this.Pets
    };
    
    this.storeFilterService.setFilter(filters);

    this.searchMapFilters = {
      ...this.searchMapFilters,
      ...filters 
    };

  }

  convertNgbDateToString(date: NgbDate): string {
    return `${date.year}-${date.month}-${date.day}`;
  }

  setValuesReceived() {
    let valuesReceived = {};
    if (this.changedDefaultValues) {
      valuesReceived = {
        minPrice: this.selectedMinValue,
        maxPrice: this.selectedMaxValue,
      };
    }

    return valuesReceived;
  }

  private _getCities() {
    this._store.dispatch(CityPageActions.init());
    this.cities$ = this._store.select(CitySelectors.cities);
  }

  private _getPropertyTypes() {
    this._store.dispatch(PropertyTypesPageActions.init());
    this.propertyTypes$ = this.translate.onLangChange.pipe(
      startWith(() => this.translate.getCurrentLang),
      switchMap((id) => this._propertyTypeService.get())
    );
  }

  getFilters() {
    this.searchMapFilters = { ...this._activatedRoute.snapshot.queryParams };

    this._activatedRoute.queryParams.subscribe((params) => {
      const fromDateStr = params["fromDate"];
      const toDateStr = params["toDate"];
      // this.searchMapFilters = {};
      this.selectedMinValue = params["minPrice"] || 0;
      this.selectedMaxValue = params["maxPrice"] || 1000;
      if (fromDateStr) {
        this.fromDate = this.convertStringToNgbDate(fromDateStr);
      }

      if (toDateStr) {
        this.toDate = this.convertStringToNgbDate(toDateStr);
      }
      this.page = parseInt(params["page"]) || 1;
      this.take = parseInt(params["take"]) || 6;
      this.Adults = parseInt(params["Adults"]) || 2;
      this.Children = parseInt(params["Children"]) || 0;
      this.Pets = parseInt(params["Pets"]) || 0;
      this.Infants = parseInt(params["Infants"]) || 0;
      this.changedDefaultValues = params["changedDefaultValues"] || true;

    });

  }

  convertStringToNgbDate(dateStr: string): NgbDate {
    const dateParts = dateStr.split('-').map(part => parseInt(part, 10));
    return new NgbDate(dateParts[0], dateParts[1], dateParts[2]);
  }
  changeMinPrice(value: string) {
    this.selectedValue = value;

    this.setMaxValue(this.maxPrice);
    this.setMinValue(this.minPrice);
    this.selectedMaxValue = this.maxPrice;
    this.selectedMinValue = this.minPrice;
    this.rangeValues =
      this.currencyPipe.transform(
        this.selectedMinValue,
        'USD',
        'symbol',
        '1.0'
      ) +
      ' - ' +
      this.currencyPipe.transform(
        this.selectedMaxValue,
        'USD',
        'symbol',
        '1.0'
      );
    this.translate
      .getTranslation(`searchBar.text${value}`)
      .subscribe((translation: string) => {
        this.selectedLabel = translation;
      });
  }

  sendPriceValues() {
    let valuesReceived = {};
    if (this.changedDefaultValues) {
      valuesReceived = {
        minPrice: this.minPrice,
        maxPrice: this.maxPrice,
      };
    }
    return valuesReceived;
  }

  clearSliderFilters() {
    this.fontStyle = ''
    this.typeAccomodationService.setTypeAccomodation('')
    this.storeFilterService.clearFiltersSide();
  }

  clearType() {
    this.fontStyle = ''
    this.typeAccomodationService.setTypeAccomodation('')
    this._router.navigate([], {
      queryParams: {
        type: null
      },
      queryParamsHandling: 'merge'
    });
  }

  removeDisabledParams() {
    const queryParams = { ...this._activatedRoute.snapshot.queryParams };
    if (queryParams && this.fontStyle === 'BedBreakfast') {
      delete queryParams['bedrooms'];
      delete queryParams['bathrooms'];
      delete queryParams['permiteMascotas'];
    }
    if (queryParams && (this.fontStyle === 'Apartments' || this.fontStyle === 'Houses')) {
      delete queryParams['queenBed'];
      delete queryParams['kingBed'];
      delete queryParams['singleBed'];
      delete queryParams['matrimonialBed'];
      delete queryParams['sharedBathroom'];
      delete queryParams['privateBathroom'];
    }
    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams,
      queryParamsHandling: 'merge'
    });
  }

  clearDate() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.searchMapFilters.fromDate = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
    this.searchMapFilters.toDate = `${tomorrow.getFullYear()}-${tomorrow.getMonth() + 1}-${tomorrow.getDate()}`;
  }

  clearFilters() {
    this.searchMapFilters = {
      ...this.searchMapFilters,
      ...this._activatedRoute.snapshot.queryParams
    };
    this.clearDate();
    this.clearQueryParams();

    this.searchMapFilters.page = 0;
    this.searchMapFilters.take = 6;
    this.minPrice = 50;
    this.maxPrice = 1000;
    this.searchMapFilters.maxPrice = this.maxPrice;
    this.searchMapFilters.minPrice = this.minPrice;
    this.selectedMaxValue = this.maxPrice;
    this.selectedMinValue = this.minPrice;
    this.showRange = false;
    this.rangeValues = "";

    this.defaultPrice = true
    this.reset = true;

    this.petService.petQuantity = 0;
    this.petService.pet = false;

    this.storeFilterService.clearAllFilters();
    this.changedDefaultValues = false;

    this.searchMapFilters = this._activatedRoute.snapshot.queryParams;
    this.Adults = 2;
    this.Pets = 0;
    this.Infants = 0;
    this.Children = 0;
    this.handleResetChange(this.reset);
  }


  clearQueryParams() {
    //Que no se pierda el tipo de alojamiento
    const queryParams = this._activatedRoute.snapshot.queryParams;
    const paramsToKeep = ['page', 'take', 'type'];

    let newQueryParams: any = Object.keys(queryParams)
      .filter(key => paramsToKeep.includes(key))
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: queryParams[key]
        };
      }, {});

    if (!newQueryParams.page) {
      newQueryParams.page = 0; 
    }
    if (!newQueryParams.take) {
      newQueryParams.take = this.take; 
    }
    if (!newQueryParams.toDate) {
      newQueryParams.toDate = this.searchMapFilters.toDate 
    }
    if (!newQueryParams.fromDate) {
      newQueryParams.fromDate = this.searchMapFilters.fromDate
    }

    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: newQueryParams
    });
  }

  setTypeFilter(typeAcc: string) {
    this.typeAccomodationService.setTypeAccomodation(typeAcc);
    this.storeFilterService.setFilter({ type: typeAcc });
    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: { type: typeAcc },
      queryParamsHandling: 'merge'
    }).then(() => {
      this.searchButtonClicked.emit();
    });

  }

  setChangedDefaultValues() {
    if (this.filters) {
      this.changedDefaultValues = true;
    }
  }

  handleAdultsChange(adults: number) {
    this.Adults = adults;
    const adu = { 'Adults': this.Adults }
    if (adults > 0) {
      this.setChangedDefaultValues()
      this.storeFilterService.setFilter(adu);
      this.searchMapFilters.Adults = this.Adults;
    }
    else if (adults === 0 && this.defaultPrice!) {
      this.storeFilterService.setFilter(adu);
      if (this.Children !== 0 || this.Infants !== 0 || this.Pets !== 0 || this.toDate || this.fromDate) {
        return
      } else {
        this.changedDefaultValues = false;
      }
    }
  }

  handleChildrenChange(children: number) {
    this.Children = children;
    let chil = { 'Children': this.Children }
    if (children > 0) {
      this.setChangedDefaultValues();
      this.searchMapFilters.Children = this.Children;
      this.storeFilterService.setFilter(chil);
    }
    else if (children === 0 && this.defaultPrice!) {
      this.storeFilterService.setFilter(chil);
      if (this.Adults !== 0 || this.Infants !== 0 || this.Pets !== 0 || this.toDate || this.fromDate) {
        return
      } else {
        this.changedDefaultValues = false;
      }
    }
  }
  handlePetsChange(pets: number) {

    this.Pets = pets;
    this.petService.petQuantity = pets;
    let pet = { 'Pets': this.Pets }
    if (pets > 0) {
      this.setChangedDefaultValues();
      this.searchMapFilters.Pets = this.Pets;
      this.searchMapFilters.permiteMascotas = 1; // Assign a value to the petFriendly property
      this.storeFilterService.setFilter(pet);
      this.storeFilterService.setFilter({ permiteMascotas: 1 });
    } else {
      delete this.searchMapFilters.permiteMascotas;
      this.petService.petQuantity = 0;
      this.searchMapFilters.Pets = pets;
      this.storeFilterService.setFilter(pet);
      if (this.defaultPrice && (this.Children !== 0 || this.Infants !== 0 || this.Adults !== 0 || this.toDate || this.fromDate)) {
        return;
      } else {
        this.changedDefaultValues = false;
      }
    }
  }

  handleInfantsChange(infants: number) {
    this.Infants = infants;
    let inf = { 'Infants': this.Infants }
    if (infants > 0) {
      this.searchMapFilters.Infants = this.Infants;
      this.searchMapFilters.Infants = this.Infants;
      this.setChangedDefaultValues()
      this.storeFilterService.setFilter(inf);
    }
    else if (infants === 0 && this.defaultPrice!) {
      this.storeFilterService.setFilter(inf);
      if (this.defaultPrice && (this.Children !== 0 || this.Infants !== 0 || this.Adults !== 0 || this.toDate || this.fromDate)) {
        return
      } else {
        this.changedDefaultValues = false;
      }
    }
  }



  showModalFilter() {
    this._matDialog
      .open(FiltersSideMenuComponent, {
        position: {
          top: '0px',
          right: '0px'
        },
        maxWidth: '400px',
        maxHeight: '98989898px',
        height: '100%',
        width: '90%',
      }).afterOpened().subscribe(() => {
        this.storeFilterService.filterChanged.subscribe((filterSide) => {
          this.searchButtonClicked.emit();
        }
        );
      });
  }

  onDateRangeSelected(event: { fromDate: NgbDate | null, toDate: NgbDate | null }) {
    this.fromDate = event.fromDate;
    this.toDate = event.toDate;
    if (this.fromDate || this.toDate) this.setChangedDefaultValues()
  }

  handleResetChange(reset: boolean) {
    this.reset = reset;
  }



  detectBoundariesChange() {
    this._activatedRoute.queryParams.subscribe((params) => {
      this._activatedRoute.url
      const { north, south, west, east } = params;
      if (north || south || west || east) {
        this.getFilters();
        this.loadSearchAmountParams();
        this._setFilters();
      }
      const sideFilters: sideFilters = this.storeFilterService.getFilterSide();

      for (const filter in sideFilters) {
        if (params[filter]) {
          // El filtro está en la URL y en los filtros laterales
        } else {
          // El filtro está en los filtros laterales pero no en la URL
          this.storeFilterService.deleteFilterSide({ [filter]: 1 });
        }
      }
    });
  }
}
