<mat-accordion>
    <mat-expansion-panel [expanded]="false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{filterItem.name | translate | titlecase}}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-list>
            <mat-list-item 
                *ngFor="let item of filterItem.filterValue" 
                (click)="item.isDisabled || disabled ? null : wasClicked(item)" 
                [disabled]="item.isDisabled||disabled"
            >
                <span [ngClass]="{'blod-span' : item.isSelected }" matListItemTitle>
                    {{item.value | translate }} <span *ngIf="!!item.cant" class="item-total">({{item.cant}})</span>
                </span>
            </mat-list-item>
        </mat-list>
    </mat-expansion-panel>
</mat-accordion>
