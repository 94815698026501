import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { AlojamientosRepository } from "../../core/repositories/alojamientos-post.repository";
import { AlojamientosResponseModel } from "../../core/domain/mapa/alojamiento-filters-model";
import { AlojamientosFilters } from "../../shared/interfaces/alojamientos-filters.interface";
import { AlojamientosResponseServer } from "../entity/mapa/alojamientos-filters-entity";
import { AlojamientosFiltersMapper } from "../mapper/alojamientos-filters-mapper";
import { environment } from "../../../environments/environment";
import { PropertyDetailPostModel } from "../../core/domain/property-detail-post-model";
import { PropertyPostDetailEntity } from "../entity/poperty-detailed-post-entity";
import { PropertyExtendedPosRepositoryMapper } from "../mapper/property-extended-post-repository.mapper";
 
@Injectable({
    providedIn: 'root'
})
export class AlojamientosPostWebRespository extends AlojamientosRepository {
    private alojamientosFiltersMapper = new AlojamientosFiltersMapper();
    private propertyPostExtendedMapper = new PropertyExtendedPosRepositoryMapper();
    constructor(private _http: HttpClient) {
        super();
    }
 
    getPostDetails(id:string): Observable<PropertyDetailPostModel> {
        return this._http.get<PropertyPostDetailEntity>(
            `${environment.baseURL}${environment.endpoints.posts}${id}`,
        ).pipe(map(val => this.propertyPostExtendedMapper.mapFrom(val)));
    }
 
 
    getAlojamientosByFilters(filters?: AlojamientosFilters | undefined): Observable<AlojamientosResponseModel[]> {
        let queryParams = '';
        if (filters) {
            const filterKeys = Object.keys(filters);
            queryParams = '?' + filterKeys
                .filter(key => filters[key] !== undefined)
                .map(key => `${key}=${filters[key]}`)
                .join('&');
        }
    
        return this._http.get<AlojamientosResponseServer>(`${environment.baseURL}${environment.endpoints.aviablePosts}${queryParams}`).pipe(
            map(response => this.alojamientosFiltersMapper.mapFromList([response]))
        );
    }
    getPrices(): Observable<any[]> {
        return this._http.get<any[]>(`${environment.baseURL}${environment.endpoints.prices}`);
    }

    getAlojamientosCoordenadas(filters?: AlojamientosFilters | undefined): Observable<AlojamientosResponseModel[]> {
        let queryParams = '';

        if (filters) {
            const filterKeys = Object.keys(filters);
            queryParams = '?' + filterKeys
                .filter(key => filters[key] !== undefined)
                .map(key => `${key}=${filters[key]}`)
                .join('&');
        }

        return this._http.get<AlojamientosResponseServer>(`${environment.baseURL}${environment.endpoints.latlangPost}${queryParams}`).pipe(
            map(response => this.alojamientosFiltersMapper.mapFromList([response]))
        );
    }

    markAsFavorite(alojamientoId: string): Observable<any> {
        return this._http.post<any>(
            `${environment.baseURL}${environment.endpoints.favoritePost}`,
            { alojamientoId }
        );
    }

    getFavorites(filters?: AlojamientosFilters | undefined): Observable<any> {
        let queryParams = '';

        if (filters) {
            const filterKeys = Object.keys(filters);
            queryParams = '?' + filterKeys
                .filter(key => filters[key] !== undefined)
                .map(key => `${key}=${filters[key]}`)
                .join('&');
        }

        return this._http.get<AlojamientosResponseServer>(`${environment.baseURL}${environment.endpoints.myFavorites}${queryParams}`).pipe(
            map(response => this.alojamientosFiltersMapper.mapFromList([response]))
        );
    }

}