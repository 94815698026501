import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


/**
 * Servicio que sirve para que pueda setear u btener el nombre 
 * de uno de lso tres filtros seleccionados en culquier componente 
 */
@Injectable({
  providedIn: 'root'
})
export class TypeFilterService {
  protected typeAccomodationFilter: string = ''
  private typeAccomodationFilterSource = new Subject<string>();

  typeAccomodationFilter$ = this.typeAccomodationFilterSource.asObservable();

  constructor() { }

  getTypeAccomodation(): string {
    return this.typeAccomodationFilter
  }

  setTypeAccomodation(typeAcc: string){
    this.typeAccomodationFilter = typeAcc;
    this.typeAccomodationFilterSource.next(typeAcc);
  }

}
