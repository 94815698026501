import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AlojamientoFiltersModel } from '../domain/mapa/alojamiento-filters-model';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  private searchInMapSubject = new Subject<any>();

  searchInMap$ = this.searchInMapSubject.asObservable();

  triggerSearchInMap( alojamientos: AlojamientoFiltersModel[]) {
    this.searchInMapSubject.next(alojamientos);
  }
  
}