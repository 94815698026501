import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrl: './paginator.component.css'
})
export class PaginatorComponent implements AfterViewInit, OnChanges {
  @Input() totalAlojamientos: any;
  @Input() take: number = 8; // Cantidad de elementos por página
  @Input() page: number = 1; // Página actual
  totalPages: number = 0; // Total de páginas
  pages: number[] = []; // Números de página
  pagesToShow: any[] = []; // Páginas para mostrar en el paginador
  @Output() changedPage = new EventEmitter<number>();



  ngAfterViewInit(): void {
    setTimeout(() => {
      this.calculateTotalPages();
      this.calculatePagesToShow();
    }, 300
    );

  }

  ngOnChanges(): void {
    this.calculateTotalPages();
    this.calculatePagesToShow();
  }

  calculateTotalPages() {
    this.totalPages = Math.ceil(this.totalAlojamientos / this.take);
  }

  setCurrentPage(page: number) {

    if (this.page != page) {
      this.page = page - 1; // Ajuste para que el índice comience desde 0
      this.calculatePagesToShow();
      this.changedPage.emit(this.page + 1); // Emitir el número de página ajustado para el usuario
    }


  }

  nextPage() {
    if (this.page < this.totalPages) {
      this.page++;
      this.calculatePagesToShow();
      this.changedPage.emit(this.page);

    }
  }

  previousPage() {
    if (this.page >= 1) {
      this.page--;
      this.calculatePagesToShow();
      this.changedPage.emit(this.page);

    }
  }

  calculatePagesToShow() {
    const maxPagesToShow = 3; // Número máximo de páginas para mostrar
    const pagesToShow: number[] = [];

    if (this.totalPages <= maxPagesToShow) {
      // Si hay menos o igual que el límite, muestra todas las páginas
      for (let i = 1; i <= this.totalPages; i++) {
        pagesToShow.push(i);
      }
    } else {
      // Calcula las páginas para mostrar, asegurando que la página actual esté centrada
      let startPage = Math.max(1, this.page + 1 - Math.floor(maxPagesToShow / 2));
      let endPage = Math.min(this.totalPages, startPage + maxPagesToShow - 1);

      // Ajusta las páginas de inicio y fin si están demasiado cerca de los extremos
      if (endPage - startPage < maxPagesToShow - 1) {
        startPage = Math.max(1, endPage - maxPagesToShow + 1);
      }

      // Agrega la primera página y puntos suspensivos si es necesario
      if (startPage > 1) {
        pagesToShow.push(1);
        if (startPage > 2) {
          pagesToShow.push(-1); // Representa los puntos suspensivos
        }
      }

      // Agrega las páginas intermedias
      for (let i = startPage; i <= endPage; i++) {
        pagesToShow.push(i);
      }

      // Agrega la última página y puntos suspensivos si es necesario
      if (endPage < this.totalPages) {
        if (endPage < this.totalPages - 1) {
          pagesToShow.push(-1); // Representa los puntos suspensivos
        }
        pagesToShow.push(this.totalPages);
      }
    }

    this.pages = pagesToShow;
  }


}
