<div class="headerContact">
    <a href="/" class="flex items-center cursor-pointer">
        <img src="../../../../assets/icons/logo.svg" class="h-16" alt="Logo" />
      </a>
   
</div>
<hr>
<div class="flex flex-cols pt-4 pb-4 pl-20 br">
    <a (click)="goBack()">
        <mat-icon id="arrowBack" class="mr-4">arrow_back</mat-icon>
    </a>

    <p class="color-cafe">{{ 'servicesForm.back' | translate}}</p>
    <div class="icons">
        <a href="https://wa.me/+50245085136" class="pr-4">
            <img class="icon" src="../../../../assets/icons/WhatsappVerde.svg" alt="">
        </a>
        <a href="https://www.instagram.com/lunanueva.portal/">
            <img class="icon" src="../../../../assets/icons/InstagramRojo.svg" alt="">
        </a>
    </div>
</div>

<div class="headBannerDiv">
    <div class="containerInner">
        <div class="containerInnerTitle">
            <span class="spanTitle">
                <h1 class="bannerTitle1">{{ "contactUsForm.textTitle" | translate }}</h1>
                <p></p>
                <p>{{ "contactUsForm.textHelp" | translate }}</p>
            </span>
        </div>
        <app-corousel class="imagen"></app-corousel>
    </div>
</div>


<div class="formulario">
    <section class="formColor">
        <div class="max-w-sm m-auto">
            <div class="p-4 rounded-lg">
                <h1 class="h1-color">{{ "contactUsForm.textFormTitle" | translate }}</h1>
                <form class="grid grid-cols-1 gap-x-8 gap-y-3 mt-8">
                    <ln-form-field-input [control]="formGroup.controls.names"
                        [label]="'servicesForm.labelFullName' | translate" [type]="'text'"></ln-form-field-input>
                    <ln-form-field-telephone [control]="formGroup.controls.phone">
                    </ln-form-field-telephone>
                    <ln-form-field-input [label]="'general.labelEmail' | translate" [type]="'text'"
                        [control]="formGroup.controls.email"></ln-form-field-input>

                    <!-- NO ES UNA PROPIEDAD -->
                    <div *ngIf="!isProperty" class="options">
                        <mat-label class="label">{{ 'servicesForm.labelOptionToBeContacted' | translate }}</mat-label>
                        <ln-form-field-select-multiple [list]="servicesTypeList" [control]="formGroup.controls.services">>

                        </ln-form-field-select-multiple>
                    </div>

                    <mat-label class="label">{{ 'servicesForm.labelMessage' | translate }}</mat-label>
                    <mat-form-field appearance="outline">
                        <textarea matInput [formControl]="formGroup.controls.message"
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="10"
                        ></textarea>
                        <mat-error
                            *ngIf="formGroup.controls.message.hasError('required') && formGroup.controls.message.touched"
                            class="form-error-msg">
                            {{ 'fieldInput.msgRequiredField' | translate}}
                        </mat-error>
                    </mat-form-field>
                    <div class="flexDescribe">
                        <p class="describe" *ngIf="!isProperty">{{'servicesForm.labelDescribe'|translate}}</p>
                        <p class="counter">{{contadorCaracteresMessage}}/250</p>
                    </div>
                    <!-- <p class="counter">{{contadorCaracteresMessage}}/250</p> -->



                    <!-- ES UNA PROPIEDAD -->
                    <div *ngIf="isProperty">
                        <p class="recomended">{{'contactUsForm.textDiscount'|translate}}</p>
                        <mat-label class="label">{{'contactUsForm.textRecommendedBy'|translate}}:</mat-label>
                        <mat-form-field appearance="outline" class="mt-2">
                            <textarea 
                            matInput 
                            [formControl]="formGroup.controls.message2"
                            >
                            ></textarea>
                            <mat-error
                                *ngIf="formGroup.controls.message2.hasError('required') && formGroup.controls.message2.touched"
                                class="form-error-msg">
                                {{ 'fieldInput.msgRequiredField' | translate}}
                            </mat-error>
                        </mat-form-field>
                        <p class="counter">{{contadorCaracteresRecomendacion}}/250</p>

                    </div>



                    <div class="flex" style="margin-top: -3%;">
                        <ln-checkbox class="leading-10 grid grid-cols-1" (onChange)="checkBoxChanged($event.checked)">
                        </ln-checkbox>
                        <div class="sm:ml-0 self-center text-primary-color">
                            <span class="cafeColor"> {{ 'servicesForm.labelSendMeACopy' | translate }}
                            </span>
                        </div>
                    </div>
                </form>

                <div (click)="sendEmails()" class="mx-w mr-4 button">
                    <ln-button-loading [text]="'contactUsForm.textSend' | translate">
                    </ln-button-loading>
                </div>
                <div id="captcha_turnstile" class="flex items-center justify-center sticky bottom-2 mt-5"></div>
                <!-- <ln-button [text]="'servicesForm.btnSend'" (clickButton)="sendEmails()"
                        [disabled]="formGroup.invalid">
                    </ln-button> -->

            </div>

        </div>
    </section>
    <div *ngIf="card" class="summary">
        <h1>{{'contactUsForm.textThisProperty'|translate}}:</h1>
        <app-reservartion-card [card]="card" [definePrice]="definePrice"></app-reservartion-card>
        <div class="checkin">
            <h2>{{'contactUsForm.textCheckIn' | translate}}</h2>
            <h3>{{fromDate}} - {{toDate}}</h3>
            <hr class="checkin">
            <h2>{{'contactUsForm.textGuests'| translate}}</h2>
            <h3>{{guests}}</h3>
            <hr>

        </div>
    </div>
</div>