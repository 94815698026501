import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ToastMessage, ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css']
})
export class ToastComponent implements OnInit {
  toast: ToastMessage | null = null;
  position: string = 'toast-top-right';

  constructor(private toastService: ToastService,private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.toastService.toast$.subscribe((toast) => {
      this.toast = toast;
      if (toast?.position) {
        this.setPosition(toast.position);
      }
  
      if (!toast) {
        this.toast = null;
      }
      this.cdr.detectChanges();
    });
  }
  

  setPosition(position: string) {
    this.position = position;
  }
}